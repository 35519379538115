import Blogtopsection from "./Blogtopsection";
import Footer from "../Footer";
import Headers from "../Headers";
import Navpages from "../Navpages";
function Blogmerge()  {
    return (
 <div>
    <Headers/>
        <Navpages/>
  <Blogtopsection/>
  <Footer/>
 </div>
        
     
    );
  }
  
  export default Blogmerge;
  