import { useState, useEffect } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import { Link } from "react-router-dom";
import "react-circular-progressbar/dist/styles.css";
import axios from "axios";
import UserService from "../../../services/UserService";
import Right_Arrow_Icon from "../../../assets/img/Right_Arrow_Icon.png";
import qs from "qs";
import Keycloak_config from "../../../services/Keycloak_config";
import avatar from "../../../assets/img/Avatar.svg";

const ProfileCard = ({ onProfilePercentage, onPersonalPercentage, onEducationPercentage, onTechnicalPercentage }) => {
  const [percentage, setProfilePercentage] = useState("0");
  const [selectedTab, setSelectedTab] = useState("default");
  const profilePicUrl = process.env.REACT_APP_NODEJS_TS;
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [AmazonKey, setAmazonKey] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  let AWSKey = null;


  useEffect(() => {
    try {
      axios
        .post(
          `${apiBaseUrl}/realms/${process.env.REACT_APP_KEYCLOAK_REALM_NAME}/protocol/openid-connect/token`,
          qs.stringify(Keycloak_config),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then(async (response) => {
          const accessToken = response.data.access_token;


          // Get the user's ID by email using the inner getUserIdByusername function
          const userId = UserService.getUserId()

          // Use the obtained access token to fetch user profile data
          axios
            .get(`${apiBaseUrl}/admin/realms/${process.env.REACT_APP_KEYCLOAK_REALM_NAME}/users/${userId}`, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            })
            .then((profileResponse) => {
              const userProfile = profileResponse.data;


              localStorage.setItem("AWSS3Key", AWSKey);

              profileResponse.data.attributes["AWSKey"]
                ? setAmazonKey(profileResponse.data.attributes["AWSKey"][0])
                : setAmazonKey(null);
              const requestData = {
                userId: userId,
              };
              axios
                .post(
                  `${profilePicUrl}/get-profile-percentage`,
                  qs.stringify(requestData),
                  {
                    headers: {
                      "Content-Type": "application/x-www-form-urlencoded",
                    },
                  }
                )
                .then(async (response) => {

                  let percentage_value = response.data.percentage
                    ? response.data.percentage.slice(0, -1)
                    : "0";
                  setProfilePercentage(percentage_value); //
                  onProfilePercentage(percentage_value);
                  var { personal } = response.data;
                  var { education } = response.data;
                  var { technicalSkills } = response.data;
                  onPersonalPercentage(personal);
                  onEducationPercentage(education);
                  onTechnicalPercentage(technicalSkills);

                })
                .then(() => {
                  if (profileResponse.data.attributes["AWSKey"]) {
                    if (profileResponse.data.attributes["AWSKey"][0] !== null) {
                      axios
                        .post(`${profilePicUrl}/get-presigned-profile-url`, {
                          key: profileResponse.data.attributes["AWSKey"][0],
                        })
                        .then((profilePicResponse) => {
                          const profilePic = profilePicResponse.data.data;
                          // const finalProfilePic = urlToImage(profilePic);

                          localStorage.setItem("imageURL", profilePic);
                          setProfilePic(profilePic);

                        });
                    }
                  } else {
                    localStorage.setItem("imageURL", avatar);
                  }
                });
            });
        })
        .catch((error) => {
          console.error("Error obtaining access token:", error);
        });
    } catch (error) {
      console.error("Axios Error", error);
    }
  }, []);

  return (
    <div
      className="desktopview_sticky"
      style={
        window.innerWidth < 577 ? { marginTop: "0px" } : { marginTop: "20px" }
      }
    >
      <div
        style={
          window.innerWidth < 577
            ? {
              width: "100%",
              height: "auto",
              marginTop: "32px",
              padding: "32px",
              background: "white",

              border: "1px #EBEDF0 solid",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              gap: 28,
              display: "inline-flex",
            }
            : {
              width: "100%",
              height: "auto",
              marginTop: "32px",
              padding: "32px",
              background: "white",
              borderRadius: 20,
              border: "1px #EBEDF0 solid",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              gap: 28,
              display: "inline-flex",
            }
        }
      >
        <div
          style={{
            alignSelf: "stretch",
            justifyContent: "center",
            alignItems: "center",
            gap: 24,
            display: "inline-flex",
          }}
        >
          <div style={{ width: 83, height: 87, position: "relative" }}>
            <div>
              <img
                src={AmazonKey ? profilePic : avatar}

                className="circular_status"
              />
            </div>
            <div
              style={{
                width: 84,
                height: 84,
                transform: "rotateZ(42deg)",
              }}
            >
              <CircularProgressbar value={percentage} maxValue={131} />
            </div>
            <div
              style={{
                width: 50,
                height: 20,
                paddingTop: 4,
                paddingBottom: 2,
                paddingLeft: 12,
                paddingRight: 12,
                left: 17,
                top: 0,
                position: "absolute",
                background: "white",
                borderRadius: 16,
                border: "1px #EBEDF0 solid",
                justifyContent: "center",
                alignItems: "center",
                gap: 10,
                display: "inline-flex",
              }}
            >
              <div
                style={{
                  color: "#27AE60",
                  fontSize: 12,
                  fontFamily: "Inter",
                  fontWeight: "700",
                  wordWrap: "break-word",
                  display: "flex",
                }}
              >
                {`${percentage}%`}
              </div>
            </div>
          </div>
          <div
            style={{
              flex: "1 1 0",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 12,
              display: "inline-flex",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                color: "black",
                fontSize: 20,
                fontFamily: "Inter",
                fontWeight: "700",
                wordWrap: "break-word",
              }}
            >
              Complete Your Profile
            </div>
            {window.innerWidth > 577 && (
              <div
                style={{
                  alignSelf: "stretch",
                  color: "#4A5965",
                  fontSize: 14,
                  fontFamily: "Inter",
                  fontWeight: "400",
                  wordWrap: "break-word",
                }}
              >
                Completing your profile helps us recommend the right job role
                for you!
              </div>
            )}
          </div>
        </div>
        <div className="nav-item">
          <Link to="/dashboard/profile" style={{ textDecoration: "none" }}>
            <div
              className={selectedTab == "Profile" ? "active" : "none"}
              style={{ textDecoration: "none" }}
            >
              <button
                className="buttonbkcolornone2"
                style={{
                  padding: 0,
                  color: "#0B6AEA",
                  fontSize: 16,
                  fontFamily: "Inter",
                  fontWeight: "700",
                  wordWrap: "break-word",
                }}
              >
                <span>View & Update Profile</span>{" "}
                <img
                  src={Right_Arrow_Icon}
                  style={{ marginLeft: 6, height: 22 }}
                  alt=""
                />
              </button>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );

};



export default ProfileCard;
