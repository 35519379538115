import Keycloak from "keycloak-js";

const _kc = new Keycloak(
  `/${
    process.env.REACT_APP_KEYCLOAK_REALM_NAME === "creamcollar_prod"
      ? "keycloakProd"
      : "keycloakDev"
  }.json`
);
/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  _kc.init({
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    pkceMethod: 'S256',
  })
    .then((authenticated) => {
      if (!authenticated) {
        console.log("user is not authenticated..!");
      }
      onAuthenticatedCallback();
      window.reload(false)
    })
    .catch(console.error);
};

const doLogin = (options) => {
  _kc.login(options).catch(console.error);
};

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const getRefreshToken = () => _kc.refreshToken

const getTokenParsed = () => _kc.tokenParsed;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
  _kc.updateToken(5)
    .then(successCallback)
    .catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const getFullname = () => _kc.tokenParsed?.given_name;

// const getUserId = () => _kc.tokenParsed?.preferred_id;
const getUserId = () => _kc.tokenParsed?.sub;


const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  getTokenParsed,
  updateToken,
  getUsername,
  hasRole,
  getFullname,
  getRefreshToken,
  getUserId,
};

export default UserService;
