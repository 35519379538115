import AssessmentReportPage from "./AssessmentReportPage";
import Headers from "../../Website/Headers";
import Home from "../../Website/Platform/Dashboard/Home";
const AssessmentReport = () => {
  const content = <AssessmentReportPage />;
  return (
    <div>
      <Headers />
      <Home contentdes={content} />
    </div>
  );
}

export default AssessmentReport;
