import { useState, useEffect } from 'react';
import React from 'react';
import Navpages from './Navpages';
import { NavLink, useNavigate } from 'react-router-dom';
import { Modal, Button, Form } from 'react-bootstrap';
//import Line from '../../src/assets/img/Line.svg';
import '../Website/Profile/profile.css';// Assuming you're using React Bootstrap for modals

const Verifyotpmodel = ({ show, handleCloseModal, handleVerify, enteredOTP, setEnteredOTP, errorMessage, handleResendOTP,setOtpVerified }) => {
  const [timer, setTimer] = useState(60);
  const [otpError, setOtpError] = useState('');

  useEffect(() => {
    let interval;

    if (show && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (!show || timer === 0) {
      clearInterval(interval);
      if (timer === 0) {
        // Additional logic if needed when timer reaches 0
      }
    }

    return () => clearInterval(interval);
  }, [show, timer]);
  const showResendLink = timer === 0;

  const handleotp = () => {
    setTimer(60)
    setOtpError('');
    handleResendOTP();

  }
  const handleVerifyAndClose = async () => {
    const isVerified = await handleVerify(); // Wait for verification result
    if (!isVerified) {
      setOtpError("Invalid OTP entered, Please re-enter the valid OTP"); // Set error message
    } else {
      handleCloseModal(); // Close modal only if verified
    }
  };
  const handleOTPChange = (e) => {
    const value = e.target.value;
    // Allow only numeric input
    if (value === '' || (/^[0-9]+$/.test(value) && value.length <= 6)) {
      setEnteredOTP(value);
    }

    setOtpError(''); // Reset error message on OTP change
  };

  // function YourParentComponent() {
  //   const [isClicked, setIsClicked] = useState(false); // State to track if the box is clicked
  //   const [enteredOTP, setEnteredOTP] = useState(""); // State to store entered OTP

  //   const handleBoxClick = () => {
  //     setIsClicked(true); // Set state to true when box is clicked
  //   };

  //   const handleOTPChange = (event) => {
  //     setEnteredOTP(event.target.value); // Update entered OTP
  //   };
  // };
  // let isClicked; // Define isClicked variable
  // let handleBoxClick; // Define handleBoxClick function

  return (
    <div className="modal-content">
      <Modal className="mt" show={show} onHide={handleCloseModal} backdrop="static" keyboard={false} style={{ borderRadius: '20 !important' }}>
        <div className="vo_out" style={{ position: 'relative', width: '100%', height: '100%', background: 'white', boxShadow: '0px 8px 40px rgba(9, 44, 76, 0.08)', borderRadius: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', display: 'inline-flex' }}>
          {/* <button
          // onClick={handleCloseModal}
          style={{
            position: 'absolute',
            backgroundColor: 'white',
            color: 'gray',
            width: '25px',
            top: 10,
            right: 10,
            height: '25px',
            border: '1px solid #E2EBF3', // Add a gray border
            background: '#E2EBF3', // Set the background to white
            fontSize: '1rem',
            cursor: 'pointer',
            borderRadius: '10px', // Round the corners of the button
            padding: '5px', // Add some padding around the icon
            display: 'flex', // Use flex to center the icon
            alignItems: 'center', // Align icon vertically
            justifyContent: 'center', // Align icon horizontally
          }}
        >
          X */}
          {/* </button> */}
          <div className="vo_header" style={{ alignSelf: 'stretch', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', display: 'flex' }}>
            <div className='vo_head' style={{ alignSelf: 'stretch', color: '#00213D', fontFamily: 'Inter', wordWrap: 'break-word' }}>Mobile Number Verification</div>
            <div style={{ height: 32, alignSelf: 'stretch', color: '#4A5965', fontSize: 12, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word' }}>An SMS with the One Time Password (OTP) have been sent to your mobile number</div>
          </div>
          {otpError && (
            <div style={{ color: 'red', alignSelf: 'stretch', fontSize: 12, fontFamily: 'Inter', fontWeight: '400', textAlign: 'center' }}>
              {otpError}
            </div>
          )}

          <div className='vo_high' style={{ alignSelf: 'stretch', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 20, display: 'flex' }}>


            <div style={{ alignSelf: 'stretch', height: 48, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-end', gap: 24, display: 'flex' }}>
              <div style={{ alignSelf: 'stretch', height: 48, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 4, display: 'flex' }}>
                <input
                  type="otp"
                  id="otp"
                  name="otp"
                  style={{ alignSelf: 'stretch', paddingLeft: 20, paddingRight: 20, paddingTop: 12, paddingBottom: 12, background: 'white', borderRadius: 8, border: '1px #E2EBF3 solid', justifyContent: 'flex-start', alignItems: 'center', display: 'inline-flex' }}
                  placeholder="Enter Mobile Number OTP"
                  value={enteredOTP}
                  onChange={handleOTPChange}
                  required
                />
              </div>
            </div>




            <div style={{ alignSelf: 'stretch', justifyContent: 'flex-end', alignItems: 'flex-start', gap: 8, display: 'inline-flex' }}>
              <div style={{ color: '#80919F', fontSize: 12, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word' }}>Time Remaining: {timer}s</div>
              {timer === 0 && (
                <>
                  <img src={""} alt="" style={{ verticalAlign: 'top' }} />
                  <div style={{ color: '#0B6AEA', fontSize: 12, fontFamily: 'Inter', fontWeight: '400', textDecoration: 'underline', cursor: 'pointer', wordWrap: 'break-word' }} onClick={handleotp}>
                    Resend OTPs
                  </div>
                </>
              )}
            </div>


            <div >
              <button
                className='vo_width'
                onClick={handleVerifyAndClose}

                style={{
                  fontWeight: '600',
                  fontFamily: 'Inter',
                  fontSize: 14,
                  color: enteredOTP.trim().length > 0 ? 'white' : '#4A5965',
                  height: 44,
                  alignSelf: 'stretch',
                  paddingLeft: 32,
                  paddingRight: 32,
                  paddingTop: 12,
                  paddingBottom: 12,
                  background: enteredOTP.trim().length > 0 ? '#0B6AEA' : '#E2EBF3',
                  borderRadius: 8,
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'inline-flex'
                }}            >
                Verify OTP
              </button>
            </div>
            <Button onClick={() => {
              setOtpVerified(false)
              handleCloseModal()
            }} style={{ height: 44, backgroundColor: '#fff', alignSelf: 'stretch', paddingLeft: 32, paddingRight: 32, paddingTop: 12, paddingBottom: 12, borderRadius: 8, border: '1px #0B6AEA solid', justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>

              <div className='vo_back' style={{ color: '#0B6AEA', fontFamily: 'Inter', wordWrap: 'break-word' }}>Go Back</div>

            </Button>

          </div>
          <div style={{ alignSelf: 'stretch', height: '30px', overflow: 'hidden', fontSize: '12px', fontWeight: '400', lineHeight: '15px' }}>
            <span style={{ color: '#4A5965', wordWrap: 'break-word' }}>By creating an account or logging in, you agree to CreamCollar’s </span>
            <span style={{ color: '#4A5965', textDecoration: 'underline', wordWrap: 'break-word' }}>Conditions of Use</span>
            <span style={{ color: '#4A5965', wordWrap: 'break-word' }}> and </span>
            <span style={{ color: '#4A5965', textDecoration: 'underline', wordWrap: 'break-word' }}>Privacy Policy</span>
            <span style={{ color: '#4A5965', wordWrap: 'break-word' }}>.</span>
          </div>
        </div>

      </Modal >
    </div >
  );
};


export default Verifyotpmodel;