import React from 'react';

function CdMobile({ module, handleLoginClick, progressPercentage }) {
    return (
        <div className='cd_box' style={{}}>
            <div className='cd_footer' style={{ alignSelf: 'stretch', alignItems: 'center', gap: 8, display: 'inline-flex' }}>
                <div style={{ color: '#4A5965', fontSize: 14, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word' }}>
                    <div>
                        <div style={{ display: 'inline-flex', gap: 8, alignItems: 'center' }}>
                            <span>{module} Modules</span>
                            <span style={{ width: '4px', height: '4px', background: '#4A5965', borderRadius: 9999 }} />
                            <span>Beginner</span>
                            <span style={{ width: '4px', height: '4px', background: '#4A5965', borderRadius: 9999 }} />
                            <span>3 hours</span>
                        </div>
                    </div>
                </div>
            </div>
            <button className='cd_button' onClick={handleLoginClick} style={{ padding: '12px 24px', background: '#0B6AEA', borderRadius: 8, alignItems: 'flex-start', display: 'inline-flex' }}>
                <div style={{ color: 'white', fontSize: 14, fontFamily: 'Inter', fontWeight: '600', wordWrap: 'break-word' }}>
                    {
                        progressPercentage === 100
                            ? "Rewatch the Course"
                            : progressPercentage > 0 && progressPercentage <= 100
                                ? "Resume Learning"
                                : "Start Learning"
                    }
                </div>
            </button>
        </div>
    );
}

export default CdMobile;
