import React, { useState, useEffect } from "react";
import star from "../../../assets/img/star.png";
import JourneyContainerFailed from "./JourneyContainerFailed";
import Automotive_Course_Icon from "../../../assets/img/Automotive_Course_Icon.svg";
import completed from "../../../assets/img/Check_Icon.png";

import Describing from "./Describing";
import ProfileCard from "./ProfileCard";

import axios from "axios";
import UserService from "../../../services/UserService";
import Right_Arrow_Icon from "../../../assets/img/Right_Arrow_Icon.png";
import Profile_page from "../../Profile/Profile_page";
import { JourneyContainer } from "./JourneyContainer";
import { JourneyCourseStarted } from "./JourneyCourseStarted";
import { JourneyCourseNotStarted } from "./JourneyCourseNotStarted";
import { JourneyAssignmentNotStarted } from "./JourneyAssignmentNotStarted";
import { JourneyAssignmentStarted } from "./JourneyAssignmentStarted";
import { JourneyEngineeringAssessment } from "./JourneyEngineeringAssessment";
import JourneyEngineeringFailed from "./JourneyEngineeringFailed";
import { JourneyEnggInProgress } from "./JourneyEnggInProgress";
import { JourneySurveyStarted } from "./JourneySurveyStarted";
import JourneyStemInProgress from "./JourneyStemInProgress";
import "./journeycomponent.css";
import stem_img from "../../../assets/img/stem_img.svg";
import lock_img from "../../../assets/img/lock_img.svg";
import qs from "qs";
import oidcConfig from "./oidcconfic";
import AccordionUsage from "./AccordianUsage";
import SubmissionTimer from "./SubmissionTimer";
import Keycloak_config from "../../../services/Keycloak_config";
import DeadLineExceededPage from "./DeadLineExceededPage";
import TestAborted from "./TestAborted";
import ReportTheIssue from "./ReportTheIssue";
import { SpinningCircleLoader } from "react-loaders-kit";
import ProfileCompletionPopUp from "./ProfileCompletionPopUp";
import JourneyEnggFailed from "./JourneyEnggFailed";

function Journey() {
  const nodeapiBaseUrl = process.env.REACT_APP_NODEJS_TS;
  const courseProgressID = process.env.REACT_APP_COURSE_ID;
  const surveyID = process.env.REACT_APP_SURVEY_COURSE_ID;
  const [selectedTab, setSelectedTab] = useState("default");
  const [selectedContent, setContentChange] = useState();
  const [moodleSSOUrl, setMoodleSSOUrl] = useState("");
  const [oidcLoginUrl, setOidcLoginUrl] = useState("");

  //Moodle API---------------------------------------------------------------

  const [courses, setCourses] = useState([]);
  const [courseId, setCourseId] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [courseSections, setCourseSections] = useState({});
  const [openCourseId, setOpenCourseId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userCourses, setUserCourses] = useState([]);
  const [calculatedPercentage, setCalculatedPercentage] = useState(0);
  const email = UserService.getUsername();
  const [summary, setSummary] = useState("");
  const [courseCompletionPercentages, setCourseCompletionPercentages] =
    useState({});
  const [courseName, setCourseName] = "Automotive Industry Awareness";
  const [fullName1, setFullName1] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [userType, setUserType] = useState(null);
  const [fetchedModules, setFetchedModules] = useState([]);
  const remaningCourses = null;
  const [isUserPassed, setIsUserPassed] = useState(false);
  const [activityListData, setActivityListData] = useState([]);
  const [progressData, setProgressData] = useState(null);
  const [surveyProgressData, setSurveyProgressData] = useState(null);
  const [completedModules, setCompletedModules] = useState(null);
  const [showDeadLineExceededPage, setShowDeadLineExceededPage] =
    useState(false);
  const [deadline, setDeadline] = useState("");
  const [starttime, setstarttime] = useState("");
  const [deadlineUnix, setdeadlineUnix] = useState("");
  const [disp, setdisp] = useState("");
  const [status, setStatus] = useState("");
  const [showSubmissionTimer, setShowSubmissionTimer] = useState(true);
  const [profilePercentage, setProfilePercentage] = useState(0);
  const [personalPercentage, setpersonalPercentage] = useState(0);
  const [educationPercentage, setEducationPercentage] = useState(0);
  const [technicalPercentage, setTechnicalPercentage] = useState(0);
  const [showProfilePopup, setShowProfilePopup] = useState(false);
  const [enggInstructionRedirect, setEnggInstructionRedirect] = useState(false);
  const [isTestAbortedPopupOpen, setIsTestAbortedPopupOpen] = useState(true);
  const [isReportTheIssuePopupOpen, setIsReportTheIssuePopupOpen] = useState(true);
  const [enggAssessmentURL, setEnggAssessmentURL] = useState("");

  // Function to close the popup
  const closePopup = () => {
    setIsTestAbortedPopupOpen(false);
  };

  // Function to receive percentage value from ProfileCard
  const handleProfilePercentage = (percentage) => {
    setProfilePercentage(percentage);
  };
  const handlePersonalPercentage = (percentage) => {
    setpersonalPercentage(percentage);
  };
  const handleEducationPercentage = (percentage) => {
    setEducationPercentage(percentage);
  };
  const handleTechnicalPercentage = (percentage) => {
    setTechnicalPercentage(percentage);
  };

  const handleProfilePopup = (showPopup) => {

    var redirect = false;
    if (
      personalPercentage === "100% Complete" &&
      educationPercentage === "100% Complete" &&
      technicalPercentage === "100% Complete"
    ) {
      setShowProfilePopup(false);
      redirect = true;
    } else setShowProfilePopup(showPopup);

    if (redirect) {
      // Redirect to other page if pop-up is not displayed
      window.location.href = enggAssessmentURL;
    }
  };

  //Moodle Api const file -----------------------------------------------------

  //Moodle api call-------------------------------- --------------------

  useEffect(() => {
    // Fetch courses from your API
    const fetchCourses = async () => {
      try {
        const response = await axios.post(`${nodeapiBaseUrl}/home`, {
          email: UserService.getUsername(),
          categoryId:process.env.REACT_APP_CATEGORY_ID,
        });
        setCourses(response.data);
        console.log(response.data,'home here')
        setFetchedModules(response.data.homeCourses);
        const evalisUserPassed = response.data.homeCourses.some(
          (each) =>
            each.containerState === "Pass" ||
            each.containerState === "COMPLETED" ||
            each.containerState === "Deadline Exceeded"
        );
        setIsUserPassed(evalisUserPassed);

        setLoading(false);
        response.data.homeCourses.forEach(element => {
          if (element.title === "Engineering STEM Assessment")
            setEnggAssessmentURL(element.btn_link);
        });
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchCourses();
  }, []);

  useEffect(() => {
    const hasShown = localStorage.getItem("hasShownDeadlinePopup");
    if (!hasShown) {
      setShowDeadLineExceededPage(true);
      localStorage.setItem("hasShownDeadlinePopup", "true");
    }
  }, []);

  useEffect(() => {
    const oidcLoginUrl = `${oidcConfig.authority}/realms/${process.env.REACT_APP_KEYCLOAK_REALM_NAME}/protocol/openid-connect/auth?client_id=${oidcConfig.clientId}&redirect_uri=${oidcConfig.redirectUri}&scope=${oidcConfig.scope}&response_type=${oidcConfig.responseType}`;

    setMoodleSSOUrl(oidcLoginUrl);
  }, []);

  const userEmail = UserService.getUsername();

  useEffect(() => {
    const fetchProgress = async () => {
      try {
        const data = {
          email: userEmail,
          courseId: parseInt(courseProgressID),
        };
        const response = await axios.post(
          `${nodeapiBaseUrl}/course-progress`,
          data
        );
        setProgressData(response.data.courseProgress);
      } catch (error) {
        console.error("Error fetching progress:", error);
      }
    };

    fetchProgress();
  }, [userEmail]);

  useEffect(() => {
    const fetchSurveyProgress = async () => {
      try {
        const data = {
          email: userEmail,
          courseId: parseInt(surveyID),
        };
        const response = await axios.post(
          `${nodeapiBaseUrl}/course-progress`,
          data
        );
        setSurveyProgressData(response.data.courseProgress);
      } catch (error) {
        console.error("Error fetching progress:", error);
      }
    };

    fetchSurveyProgress();
  }, [userEmail]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const userEmail = UserService.getUsername();
        const requestBody = {
          courseId:process.env.REACT_APP_FILE_COURSE_ID ,
          email: userEmail,
        };
        const response = await axios.post(
          `${nodeapiBaseUrl}/file`,
          requestBody
        );

        var { status } = response.data;
        var { deadline } = response.data;
        var { starttime } = response.data;
        var { deadlineUnix } = response.data;
        setStatus(status);
        setDeadline(deadline);
        setstarttime(starttime);
        setdeadlineUnix(deadlineUnix);

        if (status === "Deadline Exceeded") {
          setShowDeadLineExceededPage(true); // Show the DeadLineExceededPage if the deadline is exceeded
        }
      } catch (error) {
        console.error("Error fetching DeadLineExceeded data:", error);
      }
    };

    fetchData();
  }, []);

  const handleClose = () => {
    setShowDeadLineExceededPage(false); // function to handle closing the DeadLineExceededPage
  };


  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          width: "100dvw",
          height: "100dvh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <SpinningCircleLoader loading />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  var content = "";
  const tabClickHandler = (tabName) => {
    switch (tabName) {
      case "Describing":
        content = <Describing />;
        break;
      case "Profile":
        content = <Profile_page />;
        break;

      default:
        setSelectedTab("default");
        break;
    }
    setSelectedTab(tabName);
    setContentChange(content);
  };

  function getProgressDetails(moduleProgress, moduleTitle) {
    // Check if moduleProgress is null or undefined
    if (!moduleProgress) {
      return null;
    } else return moduleProgress;

    // Iterate through the progress array
    // for (const item of moduleProgress) {
    //   // Check if the name of the current item matches the provided moduleTitle
    //   if (item.name === moduleTitle) {
    //     return item;
    //   }
    // }
    // // If no match is found, return null
    // return null;
  }

  const handleCloseSubmissionTimer = () => {
    setShowSubmissionTimer(false);
  };

  const isPopupShown = localStorage.getItem("hasShownDeadlinePopup");
  const demo = status !== "new";

  return (
    <>
      <div
        hidden={
          status === "submitted" ||
          status === "Fresh" ||
          !showSubmissionTimer ||
          status === "Deadline Exceeded"
        }
      >
        <SubmissionTimer
          deadlineUnix={deadlineUnix}
          onClose={handleCloseSubmissionTimer}
        />
      </div>

      <ProfileCompletionPopUp
        showModal={showProfilePopup}
        personalDetailsPercentage={personalPercentage}
        educationalDetailsPercentage={educationPercentage}
        technicalDetailsPercentage={technicalPercentage}
      />

      <div
        style={{
          maxWidth: "1152px",
          margin: "auto",
        }}
      >
        <div className="rightContains">
          <div className="journeytop">
            {window.innerWidth < 577 && (
              <div
                className="col-sm-12 col-xs-12 col-md-4 "
                style={{
                  paddingLeft: "0px",
                  marginLeft: "-42px",
                  marginTop: "-85px",
                  width: "125%",
                }}
              >
                <ProfileCard
                  onProfilePercentage={handleProfilePercentage}
                  onPersonalPercentage={handlePersonalPercentage}
                  onEducationPercentage={handleEducationPercentage}
                  onTechnicalPercentage={handleTechnicalPercentage}
                />
              </div>
            )}
            <div
              className="jourrneytitle"
              style={{ margin: 0, marginTop: 32, marginBottom: 24 }}
            >
              Your Learning Journey
            </div>
          </div>

          <div className="journeyflow row">
            <div className=" col-sm-12 col-xs-12 col-md-8  ">
              <div>
                {isUserPassed && (
                  <AccordionUsage
                    activityList={fetchedModules}
                    progressDetails={progressData}
                  />
                )}
              </div>
              {fetchedModules.map((eachModule) => {
                return (
                  <div
                    style={
                      window.innerWidth < 577 ? { margin: -22, gap: 16 } : {}
                    }
                  >
                    {
                      <React.Fragment key={eachModule.id}>
                        {eachModule.containerState === "Fresh" &&
                          eachModule.title !==
                          "Engineering STEM Assessment" && (
                            <JourneyContainer
                              icon={
                                eachModule.containerVisibility !== "Unlocked"
                                  ? Automotive_Course_Icon
                                  : stem_img
                              }
                              containerState={eachModule.containerState}
                              icon_width="70px"
                              icon_height="70px"
                              specificid={courseId}
                              topic={eachModule.title}
                              typeoftopic={eachModule.subtitle}
                              description={eachModule.description}
                              btnname={eachModule.button_text}
                              btnlink={eachModule.btn_link}
                              dificulity_level={eachModule.level}
                              duration={eachModule.duration}
                              lock_img={
                                eachModule.containerVisibility !== "Unlocked"
                                  ? lock_img
                                  : null
                              }
                              container_visbility_class={
                                eachModule.state === "Unlocked"
                                  ? "unlocked_feature"
                                  : "locked_feature"
                              } //locked unlocked completed
                            />
                          )}

                        {eachModule.containerState === "QUIZ_NOT_STARTED" &&
                          eachModule.title !==
                          "Engineering STEM Assessment" && (
                            <JourneyContainer
                              icon={
                                eachModule.containerVisibility !== "Unlocked"
                                  ? Automotive_Course_Icon
                                  : stem_img
                              }
                              containerState={eachModule.containerState}
                              //   moodleLinkHandle={handleLoginClick}
                              icon_width="70px"
                              icon_height="70px"
                              specificid={courseId}
                              description={eachModule.description}
                              topic={eachModule.title}
                              typeoftopic={eachModule.subtitle}
                              btnname={eachModule.button_text}
                              btnlink={
                                eachModule.subtitle === "Quiz"
                                  ? process.env.REACT_APP_STEM_ASSESSMENT_LINK
                                  : process.env.REACT_APP_ASSINGMENT_LINK
                              }
                              lock_img={
                                eachModule.containerVisibility !== "Unlocked"
                                  ? lock_img
                                  : null
                              }
                              container_visbility_class={
                                eachModule.state === "Unlocked"
                                  ? "unlocked_feature"
                                  : "locked_feature"
                              } //locked unlocked completed
                            />
                          )}

                        {eachModule.containerState === "Fail" &&
                          eachModule.title !==
                          "Engineering STEM Assessment" && (
                            <JourneyContainerFailed
                              icon={stem_img}
                              containerState={eachModule.containerState}
                              //  moodleLinkHandle={handleLoginClick}
                              icon_width="70px"
                              icon_height="70px"
                              specificid={courseId}
                              topic={eachModule.title}
                              typeoftopic={eachModule.subtitle}
                              description={summary}
                              lock_img={lock_img}
                              // btnname= {eachModule.daysLeft}
                              btnname={`Retake in ${eachModule.daysLeft} days`}
                              btnlink="#"
                              dificulity_level={eachModule.level}
                              duration={eachModule.duration}
                              container_visbility_class="failed_feature" //locked unlocked completed
                            />
                          )}

                        {eachModule.containerState === "COURSE_NOT_STARTED" &&
                          eachModule.title !==
                          "Engineering STEM Assessment" && (
                            <JourneyCourseNotStarted
                              icon={stem_img}
                              containerState={eachModule.containerState}
                              icon_width="70px"
                              icon_height="70px"
                              specificid={courseId}
                              topic={eachModule.title}
                              typeoftopic={eachModule.subtitle}
                              description={eachModule.description}
                              progressDetails={progressData}
                              dificulity_level={eachModule.level}
                              duration={eachModule.duration}
                              lock_img={
                                eachModule.containerVisibility !== "Unlocked"
                                  ? lock_img
                                  : null
                              }
                              container_visbility_class={
                                eachModule.state === "Unlocked"
                                  ? "unlocked_feature"
                                  : "locked_feature"
                              } //locked unlocked completed
                            />
                          )}

                        {eachModule.containerState === "COURSE_IN_PROGRESS" &&
                          eachModule.title !==
                          "Engineering STEM Assessment" && (
                            <JourneyCourseStarted
                              icon={stem_img}
                              containerState={eachModule.containerState}
                              icon_width="70px"
                              icon_height="70px"
                              specificid={courseId}
                              topic={eachModule.title}
                              typeoftopic={eachModule.subtitle}
                              isLearningDisplay={true}
                              // progressDetails={getProgressDetails(
                              //   progressData,
                              //   eachModule.title
                              // )}
                              progressDetails={progressData}
                              btnname={eachModule.button_text}
                              btnlink={eachModule.containerLink}
                              dificulity_level={eachModule.level}
                              duration={eachModule.duration}
                              lock_img={
                                eachModule.containerVisibility !== "Unlocked"
                                  ? lock_img
                                  : null
                              }
                              container_visbility_class={
                                eachModule.state === "Unlocked"
                                  ? "unlocked_feature"
                                  : "locked_feature"
                              } //locked unlocked completed
                            />
                          )}

                        {eachModule.containerState === "draft" && (
                          <JourneyAssignmentStarted
                            icon={stem_img}
                            containerState={eachModule.containerState}
                            icon_width="70px"
                            icon_height="70px"
                            specificid={courseId}
                            topic={eachModule.title}
                            typeoftopic={eachModule.subtitle}
                            noDeadline={true}
                            dificulity_level={deadline}
                            lock_img={
                              eachModule.containerVisibility !== "Unlocked"
                                ? lock_img
                                : null
                            }
                            container_visbility_class={
                              eachModule.state === "Unlocked"
                                ? "unlocked_feature"
                                : "locked_feature"
                            } //locked unlocked completed
                          />
                        )}

                        {eachModule.containerState === "new" && (
                          <JourneyAssignmentNotStarted
                            icon={stem_img}
                            containerState={eachModule.containerState}
                            icon_width="70px"
                            icon_height="70px"
                            specificid={courseId}
                            topic={eachModule.title}
                            typeoftopic={eachModule.subtitle}
                            description={eachModule.description}
                            duration={eachModule.duration}
                            lock_img={
                              eachModule.state !== "Unlocked" ? lock_img : null
                            }
                            container_visbility_class={
                              eachModule.state === "Unlocked"
                                ? "unlocked_feature"
                                : "locked_feature"
                            } //locked unlocked completed
                          />
                        )}

                        {eachModule.containerState === "Fresh" &&
                          eachModule.title ===
                          "Engineering STEM Assessment" && (
                            <JourneyEngineeringAssessment
                              icon={
                                eachModule.containerVisibility !== "Unlocked"
                                  ? Automotive_Course_Icon
                                  : stem_img
                              }
                              handleProfilePopup={handleProfilePopup}
                              containerState={eachModule.containerState}
                              icon_width="70px"
                              icon_height="70px"
                              // specificid={courseId}
                              topic={eachModule.title}
                              typeoftopic={eachModule.subtitle}
                              description={eachModule.description}
                              deadlineUnix={deadlineUnix}
                              dificulity_level={eachModule.level}
                              duration={eachModule.duration}
                              lock_img={
                                eachModule.containerVisibility !== "Unlocked"
                                  ? lock_img
                                  : null
                              }
                              personalDetailsPercentage={personalPercentage}
                              educationalDetailsPercentage={educationPercentage}
                              technicalDetailsPercentage={technicalPercentage}
                              container_visbility_class={
                                eachModule.state === "Unlocked"
                                  ? "unlocked_feature"
                                  : "locked_feature"
                              } //locked unlocked completed
                            />
                          )}

                        {eachModule.containerState === "EngFail" &&
                          eachModule.title ===
                          "Engineering STEM Assessment" && (
                            <JourneyEnggFailed
                              icon={Automotive_Course_Icon}
                              containerState={eachModule.containerState}
                              icon_width="70px"
                              icon_height="70px"
                              specificid="40"
                              topic={eachModule.title}
                              typeoftopic={eachModule.subtitle}
                              description={summary}
                              lock_img={
                                eachModule.containerVisibility === "locked"
                                  ? lock_img
                                  : null
                              }
                              // btnname= {eachModule.daysLeft}
                              btnname={`Retake in ${eachModule.daysLeft} days`}
                              btnlink="#"
                              dificulity_level={eachModule.level}
                              duration={eachModule.duration}
                              container_visbility_class="locked_feature"
                            />
                          )}

                        {eachModule.containerState === "EFail" && (
                          <JourneyEngineeringFailed
                            icon={stem_img}
                            containerState={eachModule.containerState}
                            icon_width="70px"
                            icon_height="70px"
                            // specificid={courseId}
                            topic={eachModule.title}
                            typeoftopic={eachModule.subtitle}
                            // description={summary}
                            lock_img={lock_img}
                            // btnname= {eachModule.daysLeft}
                            btnname={`Retake in ${eachModule.daysLeft} days`}
                            btnlink="#"
                            // dificulity_level={eachModule.level}
                            // duration={eachModule.duration}
                            container_visbility_class="failed_feature" //locked unlocked completed
                          />
                        )}

                        {eachModule.containerState === "inprogress" &&
                          eachModule.title ===
                          "Engineering STEM Assessment" && (
                            <JourneyEnggInProgress
                              icon={
                                eachModule.containerVisibility !== "Unlocked"
                                  ? Automotive_Course_Icon
                                  : stem_img
                              }
                              containerState={eachModule.containerState}
                              icon_width="70px"
                              icon_height="70px"
                              // specificid="40"
                              topic={eachModule.title}
                              typeoftopic={eachModule.subtitle}
                              description={eachModule.description}
                              btnname={eachModule.button_text}
                              btnlink={eachModule.resume_link}
                              dificulity_level={eachModule.level}
                              duration={eachModule.duration}
                              lock_img={
                                eachModule.containerVisibility !== "Unlocked"
                                  ? lock_img
                                  : null
                              }
                              container_visbility_class="unlocked_feature"
                            />
                          )}

                        {eachModule.containerState === "inprogress" &&
                          eachModule.title === "Career Survey" && (
                            <JourneySurveyStarted
                              icon={
                                eachModule.containerVisibility !== "Unlocked"
                                  ? Automotive_Course_Icon
                                  : stem_img
                              }
                              containerState={eachModule.containerState}
                              icon_width="70px"
                              icon_height="70px"
                              // specificid={courseId}
                              topic={eachModule.title}
                              typeoftopic={eachModule.subtitle}
                              isLearningDisplay={true}
                              surveyProgressDetails={surveyProgressData}
                              // btnname={eachModule.button_text}
                              btnlink={eachModule.resume_link}
                              dificulity_level={eachModule.level}
                              duration={eachModule.duration}
                              lock_img={
                                eachModule.containerVisibility !== "Unlocked"
                                  ? lock_img
                                  : null
                              }
                              container_visbility_class={
                                eachModule.state === "Unlocked"
                                  ? "unlocked_feature"
                                  : "locked_feature"
                              } //locked unlocked completed
                            />
                          )}


                        {/* <JourneyStemInProgress
                          icon={stem_img}
                          containerState={eachModule.containerState}
                          //  moodleLinkHandle={handleLoginClick}
                          icon_width="70px"
                          icon_height="70px"
                          specificid={courseId}
                          topic={eachModule.title}
                          typeoftopic={eachModule.subtitle}
                          description={summary}
                          lock_img={lock_img}
                          // btnname= {eachModule.daysLeft}
                          btnname={`Retake in ${eachModule.daysLeft} days`}
                          btnlink="#"
                          dificulity_level={eachModule.level}
                          duration={eachModule.duration}
                          container_visbility_class="failed_feature" //locked unlocked completed
                        /> */}


                      </React.Fragment>
                    }
                  </div>
                );
              })}

              {!isPopupShown && (
                <DeadLineExceededPage
                  id="hasShownDeadlinePopup"
                  show={showDeadLineExceededPage}
                  handleClose={handleClose}
                  errorMessage="test"
                />
              )}

              {/* <TestAborted show={isTestAbortedPopupOpen} /> */}
              {/* <ReportTheIssue show={isReportTheIssuePopupOpen} /> */}
            </div>

            {window.innerWidth > 991 && (
              <div
                className="col-md-4 push col-sm-12 col-xs-12  "
                style={{ paddingLeft: "22px" }}
              >
                <ProfileCard
                  onProfilePercentage={handleProfilePercentage}
                  onPersonalPercentage={handlePersonalPercentage}
                  onEducationPercentage={handleEducationPercentage}
                  onTechnicalPercentage={handleTechnicalPercentage}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Journey;
