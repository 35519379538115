import { useState, useEffect } from "react";
import "./EngineeringReportPage.css";
import axios from "axios";
import UserService from "../../../services/UserService";
import Frame from "../../../assets/img/Frame_4.svg";
import Score from "../../../assets/img/Score_bar.svg";
import Dart from "../../../assets/img/Dart_icon.svg";
import Clock from "../../../assets/img/Clock.svg";
import Ellipse_463 from "../../../assets/img/Ellipse_463.png";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const EngineeringReportPage = () => {
  const scoreReportUrl = process.env.REACT_APP_NODEJS_TS;
  const email = UserService.getUsername();
  const [score, setScore] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [percentage, setpercentage] = useState("");
  const [marks, setmarks] = useState("");
  const [duration, setduration] = useState("");
  const location = useLocation();
  const courseId = location.state && location.state.courseId;
  useEffect(() => {
    const fetchScore = async () => {
      try {
        const requestBody = {
          email: UserService.getUsername(),
          courseId: courseId,
        };

        const response = await axios.post(
          `${scoreReportUrl}/scores`,
          requestBody
        );

        setScore(response.data.scores);
        var { percentage } = response.data.scores;
        var { marks } = response.data.scores;
        var { duration } = response.data.scores;
        setpercentage(percentage);
        setmarks(marks);
        setduration(duration);
      } catch (error) {
        console.error("Error fetching score:", error);
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchScore();
  }, []);

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
          background: "white",
        }}
      >
        <div className="ellipse_circle">
          <img src={Ellipse_463} alt="" style={{ width: "100%" }} />
        </div>
        <div className="frame_cup">
          <div style={{ width: 432, height: 279, position: "relative" }}>
            <img src={Frame} alt="Prize" />
          </div>
          <div className="frame_contents">
            <div
              style={{
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 4,
                display: "flex",
              }}
            >
              <div
                style={{
                  color: "#00213D",
                  fontSize: 24,
                  fontFamily: "Inter",
                  fontWeight: "700",
                  wordWrap: "break-word",
                }}
              >
                Assessment Cleared!
              </div>
            </div>
            <div className="frame_innercontents">
              <div className="score_content">
                <div className="score_percentage">
                  <div
                    style={{
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      gap: 4,
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        width: 32,
                        height: 32,
                        padding: 4,
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <div
                        style={{ width: 24, height: 24, position: "relative" }}
                      >
                        <img src={Score} alt="Score-bars" />
                      </div>
                    </div>
                  </div>
                  <div className="score_percentagebox">{percentage}</div>
                </div>
                <div className="frame_description">Final Score</div>
              </div>
              <div className="score_content">
                <div className="score_percentage" style={{ left: 291 }}>
                  <div
                    style={{
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      gap: 4,
                      display: "flex",
                    }}
                  >
                    <div
                      style={{ width: 32, height: 32, position: "relative" }}
                    >
                      <img src={Dart} alt="Dart-Board" />
                    </div>
                  </div>
                  <div className="score_percentagebox">{marks}</div>
                </div>
                <div className="frame_description" style={{ right: 268 }}>
                  Correct Answers
                </div>
              </div>
              <div className="score_content">
                <div className="score_percentage" style={{ left: 276 }}>
                  <div
                    style={{
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      gap: 4,
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        width: 32,
                        height: 32,
                        padding: 2.67,
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          width: 26.67,
                          height: 26.67,
                          position: "relative",
                        }}
                      >
                        <img src={Clock} alt="Clock" />
                      </div>
                    </div>
                  </div>
                  <div className="score_percentagebox">{duration}</div>
                </div>
                <div className="frame_description" style={{ right: 302 }}>
                  Time Taken
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="frame_display">
          <div
            style={{
              flex: "1 1 0",
              height: 44,
              justifyContent: "flex-end",
              alignItems: "flex-start",
              gap: 20,
              display: "flex",
            }}
          >
            <Link to="/dashboard/journey" style={{ textDecoration: "none" }}>
              <button
                style={{
                  background: "white",
                  paddingLeft: 24,
                  paddingRight: 24,
                  paddingTop: 12,
                  paddingBottom: 12,
                  borderRadius: 8,
                  border: "1px #0B6AEA solid",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 8,
                  display: "flex",
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    color: "#0B6AEA",
                    fontSize: 14,
                    fontFamily: "Inter",
                    fontWeight: "600",
                    wordWrap: "break-word",
                  }}
                >
                  Home
                </div>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default EngineeringReportPage;
