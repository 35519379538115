import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import qs from "qs";
import tick_Icon from "../assets/tick_Icon.svg";
import { useNavigate } from "react-router-dom";

import "./register.css";
import { NavLink } from "react-router-dom";
import Keycloak_config from "../services/Keycloak_config";

import {
  FirstNameComponent,
  LastNameComponent,
  MobileNumberComponent,
  PasswordComponent,
  SubmitButtonComponent,
} from "./RegisterComponent";
import {
  FirstNameBlock,
  LastNameBlock,
  MobileNumberBlock,
  PasswordBlock,
  SubmitButtonBlock,
} from "./RegisterBlockComponents";

function Register() {

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    mobileNumber: "",
    specialization: "Select",
    area: "Select",
  });

  const [registrationStatus, setRegistrationStatus] = useState(false);

  const [showPassword, setShowPassword] = useState(true);
  
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState("");
  const [resendDisabled, setResendDisabled] = useState(false);
  const [timer, setTimer] = useState(30); // Set the initial countdown time
  const [err, setErr] = useState("");
  const [err1, setErr1] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);

  const [hilight, sethilight] = useState(false);
  const emailAuthUrl = process.env.REACT_APP_NODEJS_TS;
  const [emailAuthorized, setEmailAuthorized] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [userExists, setUserExists] = useState(false);

  // Function to handle displaying the error modal
  const handleShowErrorModal = (errorMessage) => {
    setModalErrorMessage(errorMessage);
    setShowErrorModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "mobileNumber") {
      // Remove any non-digit characters from the input
      const cleanedValue = value.replace(/\D/g, "");
      const truncatedValue = cleanedValue.slice(0, 10);

      if (/^[5-9]\d{0,9}/.test(truncatedValue)) {
        // If it's valid and not exceeding 10 digits, concatenate it with the selected country code

        setFormData({ ...formData, mobileNumber: truncatedValue });
      } else {
        // If it's not valid, clear the mobileNumber field
        setFormData({ ...formData, mobileNumber: "" });
      }
    } else {
      // For other inputs (firstname, lastname, email, etc.), update as usual
      setFormData({ ...formData, [name]: value });
    }

    if (name === "password") {
      if (
        !/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?#&])[A-Za-z\d@$!%*?#&]{8,}/.test(
          value
        ) ||
        value.length < 8
      ) {
        setPasswordMatchError(
          "Password must contain at least 8 characters, including 1 uppercase, 1 lowercase, 1 number, and 1 special character"
        );
        setIsPasswordValid(false);
        setErr1(""); // Password is not valid
      } else {
        setPasswordMatchError("");
        setIsPasswordValid(true); // Password is valid
      }
    }
  };

  useEffect(() => {
    // Check if all fields are filled
    const allFieldsFilled = Object.values(formData).every(
      (value) => value.trim() !== "" && formData.mobileNumber.length === 10
    );
    sethilight(allFieldsFilled); // Set highlight based on whether all fields are filled
  }, [formData]);

  const combinedClickHandler = async (event) => {
    if (event) {
      event.preventDefault();
    }
    const email = formData.email;

    const emptyFields = Object.values(formData).some((value) => value === "");
    if (emptyFields) {
      setErr1("Please fill all the fields");
      sethilight(false);
      return; // Prevent form submission
    }

    if (!isPasswordValid) {
      // document.getElementById("log_lab_password").style.color = '#EB5757';
      // document.getElementById("log_lab_password").innerHTML = 'Please enter a valid password';

      //document.getElementById("log_lab_password").style.color = "#EB5757";
      //document.getElementById("invalid_password").style.display = "inline-flex";
      document.getElementById("password").style.border = "1px solid #EB5757";
      return; // Stop the form submission
    } else {
      //document.getElementById("log_lab_password").style.color = "#0B6AEA";
      //document.getElementById("invalid_password").style.display = "none";
    }

    if (formData.mobileNumber.length < 10) {
      setErr("Please enter a valid mobile number");
      return; // Stop the form submission
    } else {
      setErr("");
      const mobileNumberWithCountryCode =
        selectedCountryCode + formData.mobileNumber;
    }
  };

  const [emailAuthMessage, setEmailAuthMessage] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const changeinputfield_focus = (e) => {
    var log_lab = document.getElementById(`log_lab_${e.target.id}`);
    var log_input = document.getElementById(`${e.target.id}`);
    log_input.style.border = "1px solid #0B6AEA";
    log_lab.style.display = "block";
    log_lab.style.color = "#0B6AEA";
  };
  const changeinputfield_blur = (e) => {
    var log_lab = document.getElementById(`log_lab_${e.target.id}`);
    var log_input = document.getElementById(`${e.target.id}`);

    if (log_input.value == "") {
      log_lab.style.display = "none";
      log_input.style.border = "1px solid #EB5757";
      log_lab.style.color = "#EB5757";
      document.getElementById(`invalid_${e.target.id}`).style.display =
        "inline-flex";
    } else {
      log_input.style.border = "1px solid #E2EBF3";
      log_lab.style.color = "#4A5965";
      document.getElementById(`invalid_${e.target.id}`).style.display = "none";
    }
  };

  function validateAlphabetsOnly(e) {
    const regex = /^[A-Za-z]+$/;
    if (!regex.test(e.key)) {
      e.preventDefault(); // Prevent input of non-alphabet characters
    }
  }

  useEffect(() => {
    const handleInput = () => {
      const spinner = document.getElementById("spinner");
      if (spinner) {
        spinner.classList.add("spin-animation");
      }
    };

    document.getElementById("email").addEventListener("input", handleInput);

    // Clean up event listener on component unmount
    // return () => {
    //   document.getElementById("email").removeEventListener("input", handleInput);
    // };
  }, []);

  const changeinputfield_ph_focus = (e) => {
    var log_lab = document.getElementById(`log_ph_field`);
    var log_lab2 = document.getElementById(`log_lab_mobileNumber`);
    log_lab2.style.display = "block";
    log_lab2.style.color = "#0B6AEA ";
    log_lab.style.border = "1px solid #0B6AEA ";
  };
  const changeinputfield_ph_blur = (e) => {
    if (e.target.value.length <= 9) {
      document.getElementById("invalid_mobileNumber").style.display = "block";
      var log_lab = document.getElementById(`log_ph_field`);
      var log_lab2 = document.getElementById(`log_lab_mobileNumber`);
      log_lab2.style.color = "#EB5757";
      log_lab.style.border = "1px solid #EB5757";
    } else {
      document.getElementById("invalid_mobileNumber").style.display = "none";
      var log_lab = document.getElementById(`log_ph_field`);
      var log_lab2 = document.getElementById(`log_lab_mobileNumber`);
      log_lab2.style.color = "#4A5965";
      log_lab.style.border = "1px solid #E2EBF3";
    }
  };

  useEffect(() => {
    const handleInput = () => {
      const spinner = document.getElementById("spinner");
      if (spinner && !spinner.classList.contains("spin-animation")) {
        spinner.classList.add("spin-animation");
      }
    };

    document.getElementById("email").addEventListener("input", handleInput);

    // return () => {
    //   document.getElementById("email").removeEventListener("input", handleInput);
    // };
  }, []);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{3}$/;
    return emailRegex.test(email);
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;

    if (name === "email") {
      setFormData({ ...formData, [name]: value });
      document.getElementById("email").style.border = "1px solid #EB5757";
      const isValidEmail = validateEmail(value);
      setIsValid(isValidEmail);

      if (!isValidEmail) {
        document.getElementById("log_lab_email").style.color = "#EB5757";
        document.getElementById("email").style.border = "1px solid #EB5757";
        document.getElementById("invalid_email").style.display = "inline-flex";
        document.getElementById("email_auth_message").style.display = "none"; // Hide the "Email not authorized" message if email is invalid
        return;
      }

      if (
        value.endsWith(".com") ||
        value.endsWith(".in") ||
        value.endsWith(".edu") ||
        value.endsWith(".io")
      ) {
        const spinner = document.getElementById("spinner");
        spinner.style.display = "inline-flex"; // Show the spinner before making the API call

        try {
          const response = await axios.post(
            `${emailAuthUrl}/get-authorized-email`,
            {
              email: value,
            }
          );

          setEmailAuthMessage(response.data.message);
          setEmailAuthorized(response.data.authorized);

          if (response.data.authorized) {
            document.getElementById("invalid_email").style.display = "none";
            document.getElementById("email_auth_message").style.color =
              "#27AE60";
            document.getElementById("log_lab_email").style.color = "#0B6AEA";
            document.getElementById("email").style.border = "1px solid #0B6AEA";
            document.getElementById("email_auth_message").style.display =
              "inline-flex"; // Show the "Email authorized" message

            try {
              axios
                .post(
                  `${apiBaseUrl}/realms/${process.env.REACT_APP_KEYCLOAK_REALM_NAME}/protocol/openid-connect/token`,
                  qs.stringify(Keycloak_config),
                  {
                    headers: {
                      "Content-Type": "application/x-www-form-urlencoded",
                    },
                  }
                )
                .then((response) => {
                  const accessToken = response.data.access_token;
                  axios
                    .get(`${apiBaseUrl}/admin/realms/${process.env.REACT_APP_KEYCLOAK_REALM_NAME}/users`, {
                      params: { email: value },
                      headers: { Authorization: `Bearer ${accessToken}` },
                    })
                    .then((response) => {
                      const isUserExist =
                        response.data && response.data.length > 0;
                      setUserExists(isUserExist);
                      if (isUserExist) {
                        // User with this email already exists
                        handleShowErrorModal(
                          "Username or Email Id is Already Registered"
                        );
                        document.getElementById(
                          "email_auth_message"
                        ).style.display = "none";
                        setRegistrationStatus(true);
                        setEmailAuthMessage("Email Id is Already Exsist");

                        document.getElementById("invalid_email").innerHTML =
                          "Email Id is Already Registered";
                        document.getElementById("log_lab_email").style.color =
                          "#EB5757";
                        document.getElementById("invalid_email").style.display =
                          "inline-flex";
                        document.getElementById("email").style.border =
                          "1px solid #EB5757";
                      } else {
                        // Proceed with user registration since email is not in use
                        setRegistrationStatus(false);

                        // Additional registration logic here
                      }
                    })
                    .catch((error) => {
                      console.error("Error during user check:", error);
                    });
                })
                .catch((error) => {
                  console.error("Error getting access token:", error);
                });
            } catch (error) {
              console.error("Error getting access token:", error);
            }
          } else {
            document.getElementById("invalid_email").style.display = "none";
            document.getElementById("email_auth_message").style.display =
              "inline-flex"; // Show the "Email not authorized" message
            document.getElementById("email_auth_message").style.color =
              "#EB5757";
            document.getElementById("email").style.border = "1px solid #EB5757"; // Set border color to red
          }
        } catch (error) {
          console.error("Error fetching authorized email:", error);
          // Handle error case
        } finally {
          spinner.style.display = "none"; // Hide the spinner after API call ends (success or error)
        }
      } else {
        const spinner = document.getElementById("spinner");
        spinner.style.display = "none"; // Hide the spinner after API call ends (success or error)
        setEmailAuthMessage("Invalid Email");
        setEmailAuthorized(false);
        setUserExists(false);
      }
    }
  };

  const inputRef = useRef(null);

  const handleKeyDown = (event) => {
    if (event.keyCode === 9) {
      event.preventDefault(); // Prevent default tab behavior

      // Find the next or previous form element based on Shift key and tabIndex
      const formElements = document.querySelectorAll(
        "input, button, textarea, select"
      );
      const currentIndex = Array.from(formElements).findIndex(
        (el) => el === inputRef.current
      );

      if (event.shiftKey) {
        // Find the previous focusable element
        for (let i = currentIndex - 1; i >= 0; i--) {
          if (formElements[i].tabIndex >= 0) {
            formElements[i].focus();
            break;
          }
        }
      } else {
        // Find the next focusable element
        for (let i = currentIndex + 1; i < formElements.length; i++) {
          if (formElements[i].tabIndex >= 0) {
            formElements[i].focus();
            break;
          }
        }
      }
    }
  };

  return (
    <div id="kc-form" style={{ background: "#F6F9FC" }}>
      <div
        id="kc-form-wrapper"
        style={{
          background: "#F6F9FC",
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
          minHeight: "100vh",
        }}
      >
        <div
          id="content_write"
          style={{
            padding: "40px 0",
            width: "100%",
            height: "100%",
            position: "relative",
            background: "#F6F9FC",
            textAlign: "center",
            display: "inline-flex",
            gap: "20px",
            flexDirection: "column",
          }}
        >
          <div style={{ width: "100%" }}>
            <div className="login_logo"></div>
          </div>
          <div
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              display: "inline-flex",
            }}
          >
            <div>
              <div
                className="outercontainer"
                style={{
                  height: "100%",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 12,
                  display: "inline-flex",
                }}
              >
                <div style={{}} className="outerwhitecontainer">
                  <div
                    style={{ width: "100%" }}
                    className="innerwhitecontainer"
                  >
                    <div style={{}} classname="accounttopdiv">
                      <div style={{}} className="h2text">
                        Create a new account for free
                      </div>
                      <div
                        style={{
                          display: "inline-flex",
                          flexDirection: "row",
                          gap: 8,
                        }}
                      >
                        <div style={{}} className="h5text">
                          Already have an account?
                        </div>
                        <div
                          className="h5text"
                          style={{ color: "#0B6AEA", textDecoration: "none" }}
                        >
                          <NavLink
                            to="/loginpage"
                            style={{ textDecoration: "none" }}
                          >
                            Log in
                          </NavLink>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        alignSelf: "stretch",
                        height: '"auto"',
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <div style={{}} className="belowsiginupbtn">
                        <form style={{ width: "100%" }}>
                          <div
                            style={{
                              width: "100%",
                              alignSelf: "stretch",
                              height: "auto",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                              alignItems: "flex-start",
                              gap: "24px",
                              display: "flex",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                alignSelf: "stretch",
                              }}
                            >
                              <div className="input-container">
                                <label
                                  id="log_lab_email"
                                  style={{
                                    display: "none",
                                    color: "#0B6AEA",
                                    marginLeft: "12px",
                                    position: "absolute",
                                    marginTop: "-7px",
                                    zIndex: 1,
                                    fontSize: "12px",
                                    padding: "0 8px",
                                    background: "white",
                                    fontFamily: "Inter",
                                    fontWeight: 400,
                                    lineHeight: "15px",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  Email ID
                                </label>
                                <input
                                  className="login_btn_hover"
                                  id="email"
                                  style={{
                                    marginBottom: 0,
                                    padding: "12px 20px",
                                    alignSelf: "stretch",
                                    height: "57px",
                                    background: "white",
                                    borderRadius: "8px",
                                    borderColor: "#E2EBF3",
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                    alignItems: "flex-start",
                                    display: "flex",
                                  }}
                                  type="email"
                                  // id="email"
                                  name="email"
                                  placeholder="Email ID"
                                  value={formData.email}
                                  onChange={handleChange}
                                  onPaste={() => handleChange}
                                  // onHover={changeinputfield_focus}
                                  onFocus={changeinputfield_focus}
                                  onBlur={(e) => {
                                    changeinputfield_blur(e);
                                  }}
                                  required
                                  autoComplete="off"
                                />
                                <div className="spinner" id="spinner"></div>
                                {emailAuthorized && !userExists && (
                                  <img
                                    src={tick_Icon}
                                    alt="verified"
                                    style={{
                                      position: "absolute",
                                      right: 5,
                                      top: 18,
                                      width: 22,
                                    }}
                                  />
                                )}
                              </div>
                              <div style={{ display: "flex" }}>
                                <span
                                  id="invalid_email"
                                  style={{
                                    display: "none",
                                    width: "100%",
                                    justifyContent: "start",
                                    color: "#EB5757",
                                    fontSize: "12px",
                                    fontFamily: "Inter",
                                    fontWeight: 400,
                                    lineHeight: "15px",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  Invalid Email ID
                                </span>
                                <span
                                  id="email_auth_message"
                                  style={{
                                    display: "inline-flex",
                                    width: "100%",
                                    justifyContent: "start",
                                    fontSize: "12px",
                                    fontFamily: "Inter",
                                    fontWeight: 400,
                                    lineHeight: "15px",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {emailAuthMessage}
                                </span>
                              </div>
                            </div>

                            {emailAuthorized && !userExists ? (
                              <FirstNameComponent
                                formData={formData}
                                handleInputChange={handleInputChange}
                                changeinputfield_focus={changeinputfield_focus}
                                changeinputfield_blur={changeinputfield_blur}
                                validateAlphabetsOnly={validateAlphabetsOnly}
                                tabIndex={1}
                                onKeyDown={handleKeyDown}
                              />
                            ) : (
                              <FirstNameBlock />
                            )}

                            {emailAuthorized && !userExists ? (
                              <LastNameComponent
                                formData={formData}
                                handleInputChange={handleInputChange}
                                changeinputfield_focus={changeinputfield_focus}
                                changeinputfield_blur={changeinputfield_blur}
                                validateAlphabetsOnly={validateAlphabetsOnly}
                              />
                            ) : (
                              <LastNameBlock />
                            )}

                            {emailAuthorized && !userExists ? (
                              <MobileNumberComponent
                                formData={formData}
                                handleInputChange={handleInputChange}
                                changeinputfield_ph_focus={
                                  changeinputfield_ph_focus
                                }
                                changeinputfield_ph_blur={
                                  changeinputfield_ph_blur
                                }
                              />
                            ) : (
                              <MobileNumberBlock />
                            )}

                            {emailAuthorized && !userExists ? (
                              <PasswordComponent
                                formData={formData}
                                passwordMatchError={passwordMatchError}
                                isPasswordValid={isPasswordValid}
                                handleInputChange={handleInputChange}
                                changeinputfield_focus={changeinputfield_focus}
                                changeinputfield_blur={(e) => {
                                  changeinputfield_blur(e);
                                }}
                              />
                            ) : (
                              <PasswordBlock />
                            )}

                            {emailAuthorized && !userExists ? (
                              <SubmitButtonComponent
                                hilight={hilight}
                                combinedClickHandler={combinedClickHandler}
                                formData={formData}
                                navigate={navigate}
                                passwordMatchError={passwordMatchError}
                                countryCode="+91"
                              />
                            ) : (
                              <SubmitButtonBlock />
                            )}
                          </div>
                        </form>
                      </div>
                      <span
                        style={{
                          color: "#EB5757",
                          fontSize: "12px",
                          fontFamily: "Inter",
                          fontWeight: 400,
                          lineHeight: "15px",
                          wordWrap: "break-word",
                        }}
                      >
                        {err}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="padding"
                  style={{
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 4,
                    display: "inline-flex",
                  }}
                >
                  <div
                    style={{
                      textAlign: "left",
                      alignSelf: "stretch",
                      color: "#80919F",
                      fontSize: "10px",
                      fontFamily: "Inter",
                      fontWeight: 400,
                      wordWrap: "break-word",
                    }}
                  >
                    <span
                      style={{
                        color: "#80919F",
                        fontSize: "10px",
                        fontFamily: "Inter",
                        fontWeight: 400,
                        wordWrap: "break-word",
                      }}
                    >
                      By creating an account or logging in, you agree to
                      CreamCollar’s{" "}
                    </span>
                  
                    <span
                      style={{
                        color: "#80919F",
                        fontSize: "10px",
                        fontFamily: "Inter",
                        fontWeight: 400,
                        wordWrap: "break-word",
                      }}
                    >
                      {" "}
                      and Privacy Policy.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
