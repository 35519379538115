import { useState } from "react";
import { IoClose } from "react-icons/io5";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useEffect } from "react";
import { FormHelperText } from "@mui/material";
import { Form } from "formik";

const SkillSelection = (props) => {
  const skillData = (JSON.parse((localStorage.getItem("skillsData"))))
  let softwareVehicleSkills = skillData.map((each) => each.skillName)
  const {
    selectedWorkSkills,
    setSelectedWorkSkills,
    discardSkills,
    toggleSkillSection,
    handleChange,
    errors,
    touched,
  } = props;
  const selectedvalueskills = selectedWorkSkills ? selectedWorkSkills : [];
  const [skillArr, setSkill] = useState(softwareVehicleSkills);
  const [selectedSkillArr, setSelectedSkillArr] = useState(selectedvalueskills);

  const chooseSkill = (event) => {
    const skillChoosed = event.target.value;
    const filtered = softwareVehicleSkills.filter(
      (each) => !selectedSkillArr.includes(each)
    );

    setSkill([...filtered]);
    setSelectedWorkSkills([...selectedSkillArr, skillChoosed]);
    setSelectedSkillArr([...selectedSkillArr, skillChoosed]);
  };

  const removeSkill = (skill) => {
    const filterSelectedSkillArr = selectedSkillArr.filter(
      (each) => each !== skill
    );
    setSkill([...skillArr, skill]);
    setSelectedWorkSkills(filterSelectedSkillArr);
    setSelectedSkillArr(filterSelectedSkillArr);
  };

  useEffect(() => {
    const filtered = softwareVehicleSkills.filter(
      (each) => !selectedWorkSkills.includes(each)
    );
    setSkill([...filtered]);

  }, []);

  return (
    <div className="skill-section-container">
      <div className="skill-flexbox">
        <div className="skill-selection-flexbox">
          <div className="selected-option-flexbox">
            {/* <select
                disabled={selectedSkillArr.length >= 7}
                className="skill-input-section"
                onChange={chooseSkill}
              >
                {skillArr.map((each) => (
                  <option
                    disabled={each === "Select a Option"}
                    selected={each === "Select a Option"}
                  >
                    {each}
                  </option>
                ))}
              </select> */}

            <FormControl
              error={errors.skillUsedInWork && touched.skillUsedInWork}
            >
              <InputLabel id="demo-simple-select-label">
                Select Your Skills *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                disabled={selectedSkillArr.length >= 7}
                label="Select Your Skills *"
                defaultValue="Select a Option"
                error={errors.skillUsedInWork && touched.skillUsedInWork}
                fullWidth
                sx={{
                  width: "330px",
                }}
                onChange={chooseSkill}
              >
                {skillArr.map((each) => (
                  <MenuItem
                    key={each}
                    disabled={each === "Select a Option" || selectedSkillArr.includes(each)}
                    selected={each === "Select a Option"}
                    value={each}
                  >
                    {each}
                  </MenuItem>
                ))}

              </Select>
              <FormHelperText>
                {errors.skillUsedInWork && touched.skillUsedInWork
                  ? errors.skillUsedInWork
                  : ""}
                </FormHelperText>
            </FormControl>
          </div>

          <ul className="skill-selected-section">
            {selectedSkillArr.map((each) => (
              <li key={each} className="skill-selected">
                <p className="skill-text">{each}</p>
                <IoClose onClick={() => removeSkill(each)} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SkillSelection;
