import image1 from './blog1.png';
function Desktoptopsectionwrapper()  {
   return (<div>

    <section className="blog-expertise container">
    <div className='blogcontainer'>
<div className='blogcontainer2'>
<img  src={image1} alt=""/>
<div className='leftcontainer'>
<div className='blogcontainerheading'>Why we started CreamCollar</div>
<div className='blogcontent'>With Software defined vehicle set to have a profound impact on Automotive Industry, we decided to take this as a challenge to help young graduates and experienced automotive industry professionals to re-imagine their careers with cloud, data, security and digital business solutions through creating the collaborative ecosystem between Industry and Academia.</div>
</div>
</div>
</div>
</section>
   </div>);
      }
      
      export  {Desktoptopsectionwrapper};