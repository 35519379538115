import { useState, useEffect } from "react";
import "./EngineeringReportPage.css";
import axios from "axios";
import UserService from "../../../services/UserService";
import React from 'react';
import { NavLink } from "react-router-dom";
import SkillChart from "./SkillChart";
import KnowledgeChart from "./KnowledgeChart";
import Vector_star from "../../../assets/img/Vector_star.svg";
import Right_Icon_BG from "../../../assets/img/Right_Icon_BG.svg";
import Up_arrow from "../../../assets/img/Up_arrow.svg";
import Down_arrow from "../../../assets/img/Down_arrow.svg";
import Group from "../../../assets/img/Group.svg";
import { SpinningCircleLoader } from "react-loaders-kit";

const RoleRecommendationPage = () => {
    const roleRecommendationUrl = process.env.REACT_APP_NODEJS_TS;
    const [isSkillsSelected, setIsSkillsSelected] = useState(true);
    const [isDataFetched, setIsDataFetched] = useState(false);

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [faqs, setFaqs] = useState([]); // Initialized to an empty array
    const [faqError, setFaqError] = useState(null);
    const [visibleAnswers, setVisibleAnswers] = useState({});
    const [recommendedRole, setRecommendedRole] = useState([]);
    const [roleError, setRoleError] = useState(null);

    const [recommendedTopTwoRoles, setRecommendedTopTwoRoles] = useState([]); // Initialized to an empty array
    const [categories, setCategories] = useState([]);
    const [data, setData] = useState([]);
    const [knowledgeCategories, setKnowledgeCategories] = useState([]);
    const [knowledgeData, setKnowledgeData] = useState([]);
    const [knowledgeSystems, setKnowledgeSystems] = useState([]); // Initialized to an empty array


    const handleToggle = () => {
        setIsSkillsSelected(!isSkillsSelected);
    };

    const skillWidth = 59; // Approximate width for "Skills"
    const knowledgeWidth = 94; // Approximate width for "Knowledge"

    const toggleAnswerVisibility = (id) => {
        setVisibleAnswers(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    console.log(UserService.getUserId());

    useEffect(() => {
        const fetchSystems = async () => {
            try {
                const requestBody = {
                    userId: UserService.getUserId(),
                };
                await axios.post(`${roleRecommendationUrl}/get-domain-recommendation`, requestBody)

                    .then((response) => {
                        const systemScores = response.data.systemScores || {}; // Default to empty object

                        // Extract categories and data from systemScores
                        const fetchedKnowledgeCategories = Object.keys(systemScores);
                        const fetchedKnowledgeData = Object.values(systemScores).map(percentage => parseInt(percentage, 10));

                        setKnowledgeCategories(fetchedKnowledgeCategories);
                        setKnowledgeData(fetchedKnowledgeData);
                        setKnowledgeSystems(response.data.systemData || []); // Default to empty array
                        setIsLoading(false);
                    })
            } catch (error) {
                console.error('Error fetching system details:', error);
                if (error.response) {
                    setError(`Error fetching system details: ${error.response.data.message || error.response.statusText}`);
                } else if (error.request) {
                    setError('Error fetching system details: No response received from server');
                } else {
                    setError(`Error fetching system details: ${error.message}`);
                }
            } finally {
                setIsLoading(false);
            }
        };

        fetchSystems();
    }, [roleRecommendationUrl]);

    useEffect(() => {
        const fetchFAQs = async () => {
            try {
                const response = await axios.get(`${roleRecommendationUrl}/get-faqs/role-recommendation`);
                console.log('FAQs Response Data:', response.data); // Debugging log
                setFaqs(response.data || []); // Default to empty array
            } catch (error) {
                console.error('Error fetching the FAQ data:', error);
                if (error.response) {
                    setFaqError(`Error fetching the FAQ data: ${error.response.data.message || error.response.statusText}`);
                } else if (error.request) {
                    setFaqError('Error fetching the FAQ data: No response received from server');
                } else {
                    setFaqError(`Error fetching the FAQ data: ${error.message}`);
                }
            }
        };

        fetchFAQs();
    }, [roleRecommendationUrl]);

    useEffect(() => {
        const fetchRecommendedRole = async () => {
            try {
                const requestBody = {
                    userId: UserService.getUserId(),
                };
                await axios.post(`${roleRecommendationUrl}/get-role-recommendation`, requestBody)
                    .then((response) => {
                        const roleSkill = response.data.roleSkill;

                        // Extract categories and data from roleSkill
                        const fetchedCategories = Object.keys(roleSkill);
                        const fetchedData = Object.values(roleSkill).map(percentage => parseInt(percentage, 10));

                        console.log('Recommended Role Response Data:', response.data);

                        setRecommendedRole(response.data.recommendedRole || []);
                        setRecommendedTopTwoRoles(response.data.topTwoRoles || []); // Default to empty array

                        setCategories(fetchedCategories);
                        setData(fetchedData);

                        setIsLoading(false);
                        // setIsSkillsSelected(false);
                        setIsDataFetched(true);
                    })
            } catch (error) {
                console.error('Error fetching recommended role:', error);
                if (error.response) {
                    setRoleError(`Error fetching recommended role: ${error.response.data.message || error.response.statusText}`);
                } else if (error.request) {
                    setRoleError('Error fetching recommended role: No response received from server');
                } else {
                    setRoleError(`Error fetching recommended role: ${error.message}`);
                }
            }
        };

        fetchRecommendedRole();
    }, [roleRecommendationUrl]);

    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (roleError) {
        return <div>{roleError}</div>;
    }

    if (isDataFetched) {
        return (
            <>
                <div
                    className="cd_header"
                    style={{
                        width: "100%",
                        background: "#F6F9FC",
                        borderBottom: "1px #E2EBF3 solid",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: 5,
                        display: "inline-flex",
                    }}
                >
                    <NavLink
                        to="/dashboard/journey"
                        style={{ textDecoration: "none" }}
                    >
                        <button
                            style={{ border: "none", padding: 0 }}
                            className="buttonbkcolornone1"
                        >
                            <img
                                src={Group}
                                style={{
                                    // marginTop: 0,
                                    width: 18,
                                    height: 15,
                                    marginTop: -3,
                                }}
                                alt=""
                            />
                        </button>
                    </NavLink>
                    <div>
                        <i
                            className="bi bi-chevron-right"
                            style={{ fontSize: "12px", color: "#667A8B" }}
                        ></i>
                    </div>
                    <div
                        style={{
                            color: "#777D84",
                            fontSize: 14,
                            fontFamily: "Inter",
                            fontWeight: "400",
                            wordWrap: "break-word",
                            marginTop: "5px",
                        }}
                    >
                        Insights & Role Recommendation
                    </div>
                </div>
                <div style={{ maxWidth: '1152px', marginTop: 80, marginBottom: 80, marginLeft: 144, marginRight: 144, position: 'relative', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 60, display: 'flex' }}>

                    <div
                        style={{
                            marginBottom: 20,
                            width: '100%',
                            height: 'auto',
                            padding: 48,
                            background: 'white',
                            borderRadius: 16,
                            overflow: 'hidden',
                            border: '1px #E2EBF3 solid',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            gap: 12,
                            display: 'flex',
                        }}
                    >
                        <div
                            style={{
                                alignSelf: 'stretch',
                                height: 80,
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                gap: 16,
                                display: 'flex',
                            }}
                        >
                            <div
                                style={{
                                    alignSelf: 'stretch',
                                    color: '#00213D',
                                    fontSize: 24,
                                    fontFamily: 'Inter',
                                    fontWeight: '700',
                                    wordWrap: 'break-word',
                                }}
                            >
                                Your Career Strengths
                            </div>
                            <div
                                onClick={handleToggle}
                                style={{
                                    width: 166,
                                    height: 38,
                                    position: 'relative',
                                    borderRadius: 31,
                                    overflow: 'hidden',
                                    border: '1px #0B6AEA solid',
                                    cursor: 'pointer',
                                }}
                            >
                                <div
                                    style={{
                                        width: isSkillsSelected ? skillWidth : knowledgeWidth,
                                        height: 22,
                                        left: isSkillsSelected ? 3 : 67,
                                        top: 2,
                                        position: 'absolute',
                                        background: '#0B6AEA',
                                        borderRadius: 16,
                                        transition: 'left 0.3s ease, width 0.3s ease',
                                    }}
                                />
                                <div
                                    style={{
                                        left: 16,
                                        top: 2,
                                        position: 'absolute',
                                        color: isSkillsSelected ? 'white' : '#0B6AEA',
                                        fontSize: 14,
                                        fontFamily: 'Inter',
                                        fontWeight: '600',
                                        wordWrap: 'break-word',
                                        transition: 'color 0.3s ease',
                                    }}
                                >
                                    Skills
                                </div>
                                <div
                                    style={{
                                        left: 77,
                                        top: 2,
                                        position: 'absolute',
                                        color: isSkillsSelected ? '#0B6AEA' : 'white',
                                        fontSize: 14,
                                        fontFamily: 'Inter',
                                        fontWeight: '600',
                                        wordWrap: 'break-word',
                                        transition: 'color 0.3s ease',
                                    }}
                                >
                                    Knowledge
                                </div>
                            </div>
                        </div>

                        {isSkillsSelected ? (
                            <div
                                style={{
                                    alignSelf: 'stretch',
                                    height: 'auto',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: 40,
                                    display: 'flex',
                                }}
                            >
                                <div style={{ width: 600, height: 'auto', position: 'relative' }}>
                                    <SkillChart categories={categories} data={data} />
                                </div>
                                <div
                                    style={{
                                        alignSelf: 'stretch',
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start',
                                        gap: 100,
                                        display: 'inline-flex',
                                    }}
                                >
                                    {recommendedTopTwoRoles.map((system, index) => (
                                        <div
                                            key={index}
                                            style={{
                                                flex: '1 1 0',
                                                height: 'auto',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',
                                                gap: 20,
                                                display: 'flex',
                                            }}
                                        >
                                            <div style={{ width: 61, height: 61, background: '#D9D9D9', borderRadius: '50%', overflow: 'hidden' }}>
                                                <img src={system.imageUrl} alt="Icon" style={{ width: '100%', height: '100%', display: 'block' }} />
                                            </div>
                                            <div
                                                style={{
                                                    flex: '1 1 0',
                                                    flexDirection: 'column',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'flex-start',
                                                    gap: 4,
                                                    display: 'inline-flex',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        alignSelf: 'stretch',
                                                        color: '#00213D',
                                                        fontSize: 16,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '700',
                                                        wordWrap: 'break-word',
                                                    }}
                                                >
                                                    {system.role}
                                                </div>
                                                <div
                                                    style={{
                                                        alignSelf: 'stretch',
                                                        color: '#4A5965',
                                                        fontSize: 14,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word',
                                                    }}
                                                >
                                                    {system.description}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div
                                style={{
                                    alignSelf: 'stretch',
                                    height: 'auto',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: 40,
                                    display: 'flex',
                                }}
                            >
                                <div style={{ width: 800, height: 'auto', position: 'relative' }}>
                                    <KnowledgeChart categories={knowledgeCategories} data={knowledgeData} />
                                </div>
                                <div
                                    style={{
                                        alignSelf: 'stretch',
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start',
                                        gap: 100,
                                        display: 'inline-flex',
                                    }}
                                >
                                    {knowledgeSystems.map((system, index) => (
                                        <div
                                            key={index}
                                            style={{
                                                flex: '1 1 0',
                                                height: 'auto',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',
                                                gap: 20,
                                                display: 'flex',
                                            }}
                                        >
                                            <div style={{ width: 61, height: 61, background: '#D9D9D9', borderRadius: '50%', overflow: 'hidden' }}>
                                                <img src={system.imageUrl} alt="Icon" style={{ width: '100%', height: '100%', display: 'block' }} />
                                            </div>
                                            <div
                                                style={{
                                                    flex: '1 1 0',
                                                    flexDirection: 'column',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'flex-start',
                                                    gap: 4,
                                                    display: 'inline-flex',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        alignSelf: 'stretch',
                                                        color: '#00213D',
                                                        fontSize: 16,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '700',
                                                        wordWrap: 'break-word',
                                                    }}
                                                >
                                                    {system.system}
                                                </div>
                                                <div
                                                    style={{
                                                        alignSelf: 'stretch',
                                                        color: '#4A5965',
                                                        fontSize: 14,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word',
                                                    }}
                                                >
                                                    {system.description}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>

                    <div style={{ marginBottom: 20, width: '100%', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 32, display: 'flex' }}>
                        <div style={{ color: '#00213D', fontSize: 24, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word' }}>Most Suited Roles For You!</div>
                        <div style={{ width: '100%', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 40, display: 'flex' }}>
                            {recommendedRole.map((role, index) => (
                                <div key={role.id} style={{ alignSelf: 'stretch', height: 'auto', paddingLeft: 32, paddingRight: 32, paddingTop: 27, paddingBottom: 27, background: 'white', boxShadow: '0px 8px 40px rgba(9, 44, 76, 0.08)', borderRadius: 12, overflow: 'hidden', border: '1px #E2EBF3 solid', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 25, display: 'flex' }}>
                                    <div style={{ alignSelf: 'stretch', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 60, display: 'inline-flex' }}>
                                        <div style={{ flex: '1 1 0', height: 'auto', justifyContent: 'flex-start', alignItems: 'center', gap: 24, display: 'flex' }}>
                                            <div style={{ alignSelf: 'flex-start', width: 64, height: 64, background: '#D9D9D9', borderRadius: '50%', overflow: 'hidden' }}>
                                                <img src={role.imageUrl} alt="Icon" style={{ width: '100%', height: '100%', display: 'block' }} />
                                            </div>
                                            <div style={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 4, display: 'inline-flex' }}>
                                                <div style={{ color: '#00213D', fontSize: 18, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word' }}>{role.domainRole}</div>
                                                <div style={{ width: 529, color: '#4A5965', fontSize: 14, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word' }}>{role.description}</div>
                                            </div>
                                        </div>
                                        {index === 0 ? (
                                            <div style={{ paddingLeft: 12, paddingRight: 12, paddingTop: 8, paddingBottom: 8, background: '#27AE60', borderRadius: 40, border: '1px #27AE60 solid', justifyContent: 'center', alignItems: 'center', gap: 8, display: 'flex' }}>
                                                <div style={{ width: 22, height: 22, background: 'white' }}>
                                                    <img src={Vector_star} alt="Star" style={{ position: 'relative', bottom: 3 }} />
                                                </div>
                                                <div style={{ color: 'white', fontSize: 14, fontFamily: 'Inter', fontWeight: '600', wordWrap: 'break-word' }}>{role.percentage} MATCH</div>
                                            </div>
                                        ) : (
                                            <div style={{ paddingLeft: 12, paddingRight: 12, paddingTop: 8, paddingBottom: 8, background: 'rgba(39, 174, 96, 0.05)', borderRadius: 40, border: '1px #27AE60 solid', justifyContent: 'center', alignItems: 'center', gap: 4, display: 'flex' }}>
                                                <div style={{ color: '#27AE60', fontSize: 16, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word' }}>{role.percentage} MATCH</div>
                                            </div>
                                        )}
                                    </div>
                                    <div style={{ alignSelf: 'stretch', height: 0, border: '1px #E2EBF3 solid' }}></div>
                                    <div style={{ alignSelf: 'stretch', justifyContent: 'center', alignItems: 'flex-end', gap: 80, display: 'inline-flex' }}>
                                        <div style={{ flex: '1 1 0', height: 66, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 32, display: 'flex' }}>
                                            <div style={{ flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 17, display: 'inline-flex' }}>
                                                <div style={{ color: 'black', fontSize: 12, fontFamily: 'Inter', fontWeight: '600', wordWrap: 'break-word' }}>Skills Required</div>
                                                <div style={{ alignSelf: 'stretch', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 8, display: 'inline-flex' }}>
                                                    {role.skills.map((skill, index) => (
                                                        <div key={index} style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 8, paddingBottom: 8, background: 'rgba(204.55, 230.78, 255, 0.50)', borderRadius: 20, justifyContent: 'center', alignItems: 'center', gap: 8, display: 'flex' }}>
                                                            <div style={{ color: '#4A5965', fontSize: 14, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word' }}>{skill}</div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <button style={{ padding: 0, background: 'white', border: 'none', justifyContent: 'flex-start', alignItems: 'center', gap: 8, display: 'flex' }}>
                                            <div style={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 4, display: 'inline-flex' }}>
                                                <div style={{ color: '#0B6AEA', fontSize: 14, fontFamily: 'Inter', fontWeight: '600', wordWrap: 'break-word' }}>Learn More To Enrol</div>
                                            </div>
                                            <div style={{ width: 20, height: 20, right: 9, position: 'relative' }}>
                                                <div style={{ bottom: 0, position: 'absolute', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 4, display: 'inline-flex' }}>
                                                    <img src={Right_Icon_BG} alt="Right_Icon" />
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            ))}
                            {roleError && <div>{roleError}</div>}
                        </div>
                    </div>

                    <div style={{ marginBottom: 20, width: '100%', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 32, display: 'flex' }}>
                        <div style={{ color: '#00213D', fontSize: 24, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word' }}>Frequently Asked Questions</div>
                        <div style={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-end', gap: 28, display: 'flex' }}>
                            {faqError ? (
                                <div>{faqError}</div>
                            ) : (
                                faqs.map(faq => (
                                    <div key={faq.id} style={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 8, display: 'flex' }}>
                                        <div style={{ width: 953, justifyContent: 'center', alignItems: 'center', gap: 40, display: 'inline-flex' }}>
                                            <div style={{ flex: '1 1 0', color: '#00213D', fontSize: 18, fontFamily: 'Inter', fontWeight: '600', wordWrap: 'break-word' }}>{faq.question}</div>
                                            <div style={{ justifyContent: 'flex-start', alignItems: 'flex-start', gap: 4, display: 'flex' }}>
                                                <div style={{ width: 32, height: 32, position: 'relative', cursor: 'pointer' }} onClick={() => toggleAnswerVisibility(faq.id)}>
                                                    <img src={visibleAnswers[faq.id] ? Up_arrow : Down_arrow} alt="toggle" />
                                                </div>
                                            </div>
                                        </div>
                                        {visibleAnswers[faq.id] && (
                                            <div style={{ width: 953, color: '#4A5965', fontSize: 16, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word' }}>{faq.answer}</div>
                                        )}
                                        <div style={{ width: 954, height: 0, border: '1px #E2EBF3 solid' }}></div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    }
    return (
        <div
            style={{
                display: "flex",
                width: "100dvw",
                height: "100dvh",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <SpinningCircleLoader loading />
        </div>
    )
};

export default RoleRecommendationPage;
