import { Link } from "react-router-dom";
import warning_icon from "../../../assets/warning_icon.svg";
function JourneyContainerFailed(properties) {
  const {
    icon,
    icon_width,
    icon_height,
    specificid,
    topic,
    typeoftopic,
  

    btnname,
    btnlink,
   
    lock_img,
    container_visbility_class, //locked unlocked completed
  } = properties;

  return (
    <>
      <div className={`common_journey_container  ${container_visbility_class}`} >

        <div className="common_spacing_div_container " style={{ zIndex: 0 }}>

          <div className="common_top_container"  >
            <div className="common_img_container">
              <img
                src={icon}
                style={
                  icon_width && icon_height
                    ? { width: icon_width, height: icon_height }
                    : { width: 43, height: 43 }
                }
                alt=""
              />
              <div className="common_content_container">
                <div className="common_topic_type_line">{typeoftopic}</div>
                <div className="common_description_container">
                  <div className="common_content_container_div">
                    <div key={specificid}>
                      <h1 className="content_topic_name">{topic} </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="passing-criteria-section">
          <img src={warning_icon} />
          <p className="warning-text">
            You need to clear the test by scoring at least 7/10 in-order to
            unlock the next activity
          </p>
        </div>
        <div className="common_button_container">
          <div className="failed-btn-section">
            <Link to={btnlink} style={{ textDecoration: "none" }}>
              <button className="common_button_area_failed">
                <div className="btn-locked">
                  <div className="btn-locked-div">
                    <img src={lock_img} />
                    <p className="btn_area_text_failed">{btnname}</p>
                  </div>
                </div>
              </button>
            </Link>

           
            <Link to='/dashboard/assessment-report' style={{ textDecoration: "none" }}>
              <button className="common_button_area_failed_report">
                <p className="btn_area_text_failed_report">View Report</p>
              </button>
            </Link>
          </div>

          {/*   <div className="course_sub_description_container">
            <div className="dificulity_level_description">
              {dificulity_level}
            </div>
            <div className="word_seperation_round" />
            <div className="duration_description">{duration}</div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default JourneyContainerFailed;
