import * as React from "react";
import { useState } from "react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import { Field } from "formik";
import FormHelperText from "@mui/material/FormHelperText";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect } from "react";

import Styles from "../profile.module.css";

const SchoolAcademics = (props) => {
  const {
    eduDetails,
    setEduDetails,
    values,
    errors,
    touched,
    handleChange,
    setErrors,
    education_action,
    seteducation_action,
    profileData,
    discardfunction3,
    handleSubmit,
    setFieldTouched,
  } = props;

  useEffect(() => {
    setErrors({});
    //setEduDetails({})
  }, [eduDetails.educationLevel]);

  let educationLevels = ["Graduation", "Class XII", "Class X"];

  const existingEducationLevels = profileData.educationaldetails.map(
    (detail) => detail.educationLevel
  );

  const filteredEducationLevels = educationLevels.filter(
    (level) => !existingEducationLevels.includes(level)
  );

  educationLevels =
    education_action === "edit" ? educationLevels : filteredEducationLevels;

  const educationBoards = [
    "Assam Higher Secondary Education Council",
    "Arunachal Pradesh Board",
    "Board of Intermediate Education (Andhra Pradesh)",
    "Board of School Education Haryana",
    "Board of School Education Uttarakhand",
    "Board of Secondary Education, Madhya Pradesh",
    "Board of Secondary Education, Manipur",
    "Central Board of Secondary Education",
    "Chhattisgarh Board of Secondary Education",
    "Council for the Indian School Certificate Examinations",
    "Council of Higher Secondary Education, Odisha",
    "Delhi Board of Senior Secondary Education",
    "Goa Board of Secondary and Higher Secondary Education",
    "Govt. of Karnataka Dept. of Pre-University Education",
    "Gujarat Secondary and Higher Secondary Education Board",
    "H.P. Board of School Education",
    "ICSE Board (Indian Council of Secondary Education)",
    "International Baccalaureate",
    "Jammu and Kashmir Board",
    "Jharkhand Academic Council, Ranchi",
    "Kerala Board of Higher Secondary Education",
    "Ladakh Board",
    "Maharashtra State Board of Secondary and Higher Secondary Education",
    "Meghalaya Board of School Education",
    "Mizoram Board of School Education",
    "Nagaland Board of School Education",
    "Punjab School Education Board",
    "Rajasthan Board of Secondary Education",
    "Sikkim Board",
    "Tamil Nadu State Board of School & Higher Secondary Education",
    "Telangana State Board of Intermediate Education",
    "The J & K State Board of School Education",
    "Tripura Board of Secondary Education",
    "U.P. Board of High School & Intermediate Education",
    "West Bengal Board of Secondary Education",
  ];

  const [boardError, setBoardError] = useState("");
  const [mediumError, setMediumError] = useState("");
  const [schoolPercentageError, setSchoolPercentageError] = useState("");
  const [passedOutYearError, setPassedOutYearError] = useState("");
  const [gradesError, setGradesError] = useState("");

  const medium = [
    "English",
    "Tamil",
    "Hindi",
    "Telugu",
    "Kannada",
    "Malayalam",
    "Bengali",
    "Gujarati",
    "Marathi",
    "Urdu",
    "Odia",
  ];

  const isBoardDisabled =
    eduDetails.educationLevel === "Class X" ||
    eduDetails.educationLevel === "Class XII";

  const setEducationLevel = (e) => {
   
    const selectedVal = e.target.value;
    setErrors({});
    values.educationLevel = selectedVal;
    setEduDetails({ ...eduDetails, educationLevel: selectedVal });
    handleChange(e);
  };

  const setBoard = (e) => {
    const selectedVal = e.target.value;
    if (!selectedVal) {
      setBoardError("Board is required");
      setErrors({ ...errors, board: "Board is required" });
    } else {
      values.board = selectedVal;
      setEduDetails({ ...eduDetails, board: selectedVal });
      setErrors({ ...errors, board: "" });
      setBoardError("");
    }
    handleChange(e);
  };

  const setMedium = (e) => {
    const selectedVal = e.target.value;
    values.medium = selectedVal;
    //setEduDetails({ ...eduDetails, medium: selectedVal });
    setErrors({ ...errors, medium: "" });
    handleChange(e);
  };

  const setStartDate = (e) => {
    const selectedVal = e.target.value;

    if (!selectedVal) {
      setPassedOutYearError("Passed Out year is required");
      setErrors({ ...errors, passedOutYear: "Passed Out year is required" });
    } else if (new Date(selectedVal) >= new Date()) {
      setPassedOutYearError("Passed Out year cannot be a future date");
      setErrors({
        ...errors,
        passedOutYear: "Passed Out year cannot be a future date",
      });
      //setPassedOutYearFlag(true);
    } else {
      setPassedOutYearError("");
      // setPassedOutYearFlag(false);
      setErrors({ ...errors, passedOutYear: "" });
    }

    handleChange(e);
  
    //setEduDetails({ ...eduDetails, startDate: e.target.value });
  };

  const setGradingType = (e) => {
    const selectedVal = e.target.value;
    values.gradingType = selectedVal;
    values.percentage = "";
    values.classxgrade = "";
    setEduDetails({
      ...eduDetails,
      gradingType: selectedVal,
      percentage: "",
      classxgrade: "",
    });
    setErrors({
      ...errors,
      gradingSystem: "",
      percentage: "",
      classxgrade: "",
    });
    handleChange(e);
  };

  /*   const setSchoolMarks = (e) => {
    const selectedVal = e.target.value;
    setEduDetails({ ...eduDetails, schoolMarks: selectedVal });
    values.schoolMarks = selectedVal;
    handleChange(e);
  }; */

  const setSchoolPercentage = (e) => {
    const selectedVal = e.target.value;

    if (!selectedVal) {
      setSchoolPercentageError("Required");
      setErrors({ ...errors, classxgrade: null, percentage: 'Required' });
    } else if (
      !/^$|^([1-9][0-9]?|100)$/.test(selectedVal) ||
      selectedVal.length > 3
    ) {
      e.target.value = selectedVal.replace(/[^0-9]$/, "");
      e.target.value = selectedVal.replace(/\D/g, "").substring(0, 3);
      setSchoolPercentageError("Enter Valid percentage from 1 to 100");
      //setSchoolPercentageFlag(true);
      //errors.percentage = "Enter Valid percentage from 1 to 100";
    } else {
      setSchoolPercentageError("");
      //  setEduDetails({ ...eduDetails, schoolPercentage: selectedVal });
      //setSchoolPercentageFlag(false);
    }
    handleChange(e);
  };

  const setSchoolCGPA = (e) => {
    const selectedVal = e.target.value;
    // setEduDetails({ ...eduDetails, schoolCGPA: selectedVal });
    values.schoolCGPA = selectedVal;
    handleChange(e);
  };

  /*  const setSchoolGrade = (e) => {
    const selectedVal = e.target.value;

    if (!selectedVal) {
      setGradesFlag(true);
      setGradesError("Required");
    
    handleChange(e);
  }; */

  const setSchoolGrade = (e) => {
    let selectedVal = e.target.value;

    if (!selectedVal) {
      //setGradesFlag(true);
      setGradesError("Required");
    }
    // Replace any character that does not match the pattern A-F for the first position and 1-6 for the second position
    if (selectedVal.length === 1) {
      selectedVal = selectedVal.replace(/[^A-F]/g, "");
      setGradesError("Enter valid grade: a letter from A to F followed by a number from 1 to 6.");
      
    } else if (selectedVal.length > 1) {
        selectedVal = selectedVal.substring(0, 1) + selectedVal.substring(1).replace(/[^1-6]/g, "");
    }

    // Ensure the value is exactly 2 characters long at most
    if (selectedVal.length === 2) {
        selectedVal = selectedVal.substring(0, 2);
    }

    // Update the input value
    e.target.value = selectedVal;

    // Validate the pattern
    if (!/^[A-F][1-6]$/.test(selectedVal)) {
        // If the pattern is invalid, set the error message
        setGradesError("Enter valid grade: a letter from A to F followed by a number from 1 to 6.");
    } else {
      // setGradesFlag(false);
      setErrors({});
      setGradesError("");
      values.schoolGrade = selectedVal;
    }
    handleChange(e);
  };

  return (
    <>
      <div className={Styles.profile_input_section}>
        <div className={Styles.profile_section_row}>
          <div className={Styles.profile_element}>
            <FormControl error={errors.educationLevel} fullWidth>
              <InputLabel id="demo-simple-select-label">Education *</InputLabel>

              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Education *"
                name="educationLevel"
                value={values.educationLevel}
                variant="outlined"
                disabled={education_action === "edit"}
                error={errors.educationLevel}
                placeholder={values.educationLevel}
                onChange={setEducationLevel}
              >
                {educationLevels.map((each) => (
                  <MenuItem key={each} value={each}>
                    {each}
                  </MenuItem>
                ))}
              </Select>

              <FormHelperText>{errors.educationLevel}</FormHelperText>
            </FormControl>
          </div>
          {/* <div className={Styles.profile_element}>
            <FormControl fullWidth error={errors.board || touched.board }>
              <InputLabel id="demo-simple-select-label">Board * </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Board *"
                name="board"
                variant="outlined"
                onChange={setBoard}
                error={errors.board || touched.board }
                value={values.board}
                disabled={!isBoardDisabled && education_action !== "edit"}
              >
                {educationBoards.map((each) => (
                  <MenuItem key={each} value={each}>
                    {each}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.board || touched.board }</FormHelperText>
            </FormControl>
          </div> */}

          <div className={Styles.profile_element}>
            <FormControl fullWidth error={errors.board}>
              <InputLabel id="demo-simple-select-label">Board *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Board *"
                value={values.board}
                onBlur={setBoard}
                onChange={setBoard}
                disabled={!isBoardDisabled && education_action !== "edit"}
                name="board"
              >
                {educationBoards.map((each) => (
                  <MenuItem key={each} value={each}>
                    {each}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.board}</FormHelperText>
            </FormControl>
          </div>
        </div>

        <div className={Styles.profile_section_row}>
          <div className={Styles.profile_element}>
            <FormControl fullWidth error={errors.medium}>
              <InputLabel id="demo-simple-select-label">
                School Medium *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="School Medium"
                value={values.medium}
                onChange={setMedium}
                name="medium"
              >
                {medium.map((each) => (
                  <MenuItem key={each} value={each}>
                    {each}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.medium}</FormHelperText>
            </FormControl>
          </div>
          <div className={Styles.profile_element}>
            <Field
              name="passedOutYear"
              as={TextField}
              type="date"
              fullWidth
              label="Passed Out Year"
              onChange={setStartDate}
              value={values.passedOutYear}
              error={errors.passedOutYear || passedOutYearError}
              helperText={errors.passedOutYear || passedOutYearError}
              InputLabelProps={{
                shrink: true,
              }}
            />

            {/*   <div class="profile_element">
              <label for="passedOutYear">Passed Out year</label>
              <input type="date" id="passedOutYear" name="passedOutYear" onChange={handleChange} />
              { errors.passedOutYear && (
              <div>{errors.passedOutYear}</div>
            )}
            </div> */}
          </div>
        </div>
        <div className={Styles.profile_input_section}>
          <div className={Styles.profile_section_row}>
            <div className={Styles.profile_element}>
              {values.educationLevel !== "Class XII" && (
                <FormControl fullWidth error={errors.gradingSystem}>
                  <InputLabel id="demo-simple-select-label">
                    Grading System *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Grading System"
                    name="gradingSystem"
                    value={values.gradingType}
                    onChange={setGradingType}
                    error={errors.gradingSystem}
                  >
                    <MenuItem value={"Percentage"}>Percentage</MenuItem>
                    <MenuItem value={"Grades"}>Grades</MenuItem>
                  </Select>
                  <FormHelperText>{errors.gradingSystem}</FormHelperText>
                </FormControl>
              )}
              {values.educationLevel === "Class XII" && (
                <Field
                  fullWidth
                  name="percentage"
                  as={TextField}
                  id="outlined-basic"
                  label="Percentage"
                  variant="outlined"
                  onChange={setSchoolPercentage}
                  error={errors.percentage}
                  helperText={errors.percentage}
                />
              )}
            </div>
            <div className={Styles.profile_element}>
              {values.gradingType === "CGPA" && (
                <div className={[Styles.profile_element, Styles.checkel]}>
                  <Field
                    fullWidth
                    name="CGPA"
                    as={TextField}
                    id="outlined-basic"
                    label="CGPA"
                    variant="outlined"
                    onChange={setSchoolCGPA}
                    /* error={collegeCGPAError}
                    helperText={collegeCGPAError} */
                  />
                </div>
              )}
              {values.gradingType === "Percentage" &&
                eduDetails.educationLevel !== "Class XII" && (
                  <div className={[Styles.profile_element, Styles.checkel]}>
                    <Field
                      sx={{
                        width: "100%",
                      }}
                      fullWidth
                      as={TextField}
                      id="outlined-basic"
                      label="Percentage"
                      name="percentage"
                      value={values.percentage}
                      variant="outlined"
                      onChange={setSchoolPercentage}
                      onBlur={setSchoolPercentage}
                      error={errors.percentage}
                      helperText={errors.percentage}
                    />
                  </div>
                )}
              {values.gradingType === "Grades" &&
                eduDetails.educationLevel !== "Class XII" && (
                  <div className={[Styles.profile_element, Styles.checkel]}>
                    <Field
                      sx={{
                        width: "100%",
                      }}
                      fullWidth
                      as={TextField}
                      id="outlined-basic"
                      label="Grade"
                      name="classxgrade"
                      variant="outlined"
                      onChange={setSchoolGrade}
                      error={errors.classxgrade}
                      helperText={errors.classxgrade}
                    />
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 24,
          display: "inline-flex",
          marginTop: 24,
        }}
      >
        <button
          style={{
            paddingLeft: 24,
            paddingRight: 24,
            paddingTop: 12,
            paddingBottom: 12,
            background: "#0B6AEA",
            borderRadius: 8,
            justifyContent: "center",
            alignItems: "center",
            border: "none",
            display: "flex",
          }}
          /*    disabled={
            percentageFlag ||
            passedOutYearFlag ||
            gradesFlag ||
            !values.educationLevel ||
            !values.board ||
            !values.medium ||
            !values.passedOutYear ||
            !values.gradingType ||
            !values.percentage ||
            !values.classxgrade ||
            !values.CGPA
          } */

          disabled={
            passedOutYearError
          }
        
          type="submit"
               
          onClick={(e) => {
          
            if (values.classxgrade === '' && values.gradingType === 'Grades') {
              e.preventDefault()
              setErrors({...errors,classxgrade:'Required'})
            }else{
              setErrors({...errors,classxgrade:null})
            }
          }}
        >
          <div className="save-changes-btn">Save Changes</div>
        </button>

        <button
          className="discard-btn"
          onClick={() => {
            setEduDetails({});
            discardfunction3();
          }}
        >
          <div className="discard-btn">Discard Changes</div>
        </button>
        {/*  <button
          type="button"
          onClick={() => {
            console.log(
              percentageFlag,
              passedOutYearFlag,
              gradesFlag,
              "booo percentageFlag , passedOutYearFlag , gradesFlag"
            );
          }}
        >
          C H E C K F L A G
        </button>  */}
      </div>
    </>
  );
};

export default SchoolAcademics;
