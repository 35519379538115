import React, { useState, useRef } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
const FirstNameComponent = ({
  formData,
  handleInputChange,
  changeinputfield_focus,
  changeinputfield_blur,
  validateAlphabetsOnly,
}) => {
  const inputRef = useRef(null);

  const nodeapiBaseUrl = process.env.REACT_APP_NODEJS_URL;
  const handleKeyDown = (event) => {
    if (event.keyCode === 9) {
      event.preventDefault(); // Prevent default tab behavior

      // Find the next or previous form element based on Shift key and tabIndex
      const formElements = document.querySelectorAll(
        "input, button, textarea, select"
      );
      const currentIndex = Array.from(formElements).findIndex(
        (el) => el === inputRef.current
      );

      if (event.shiftKey) {
        // Find the previous focusable element
        for (let i = currentIndex - 1; i >= 0; i--) {
          if (formElements[i].tabIndex >= 0) {
            formElements[i].focus();
            break;
          }
        }
      } else {
        // Find the next focusable element
        for (let i = currentIndex + 1; i < formElements.length; i++) {
          if (formElements[i].tabIndex >= 0) {
            formElements[i].focus();
            break;
          }
        }
      }
    }
  };

  return (
    <div style={{ width: "100%", alignSelf: "stretch" }}>
      <div
        style={{
          width: "100%",
          alignSelf: "stretch",
          height: "57px",
          borderRadius: "8px",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          display: "flex",
        }}
      >
        <label
          id="log_lab_firstname"
          style={{
            display: "none",
            border: "none",
            color: "#0B6AEA",
            marginLeft: "12px",
            position: "absolute",
            marginTop: "-7px",
            zIndex: 1,
            fontSize: "12px",
            padding: "0 8px",
            background: "white",
            fontFamily: "Inter",
            fontWeight: 400,
            lineHeight: "15px",
            wordWrap: "break-word",
          }}
        >
          First Name
        </label>
        <input
          // disabled={disabled}
          className="login_btn_hover"
          style={{
            marginBottom: 0,
            padding: "12px 20px",
            alignSelf: "stretch",
            height: "57px",
            paddingTop: "16px",
            paddingBottom: "16px",
            background: "white",
            borderRadius: "8px",
            border: "1px #E2EBF3 solid",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            display: "flex",
          }}
          //  tabIndex={1}
          type="text"
          id="firstname"
          name="firstname"
          placeholder="First Name"
          value={formData.firstname}
          onChange={handleInputChange}
          //onKeyDown={handleKeyDown}
          onHover={changeinputfield_focus}
          onFocus={changeinputfield_focus}
          onBlur={changeinputfield_blur}
          onKeyPress={(e) => validateAlphabetsOnly(e)}
          required
          autoComplete="off"
        />
      </div>
      <span
        id="invalid_firstname"
        style={{
          display: "none",
          width: "100%",
          justifyContent: "start",
          color: "#EB5757",
          fontSize: "12px",
          fontFamily: "Inter",
          fontWeight: 400,
          lineHeight: "15px",
          wordWrap: "break-word",
        }}
      >
        Invalid First Name
      </span>
    </div>
  );
};

const LastNameComponent = ({
  formData,
  handleInputChange,
  changeinputfield_focus,
  changeinputfield_blur,
  validateAlphabetsOnly,
}) => {
  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          width: "100%",
          alignSelf: "stretch",
          height: "57px",
          borderRadius: "8px",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          display: "flex",
        }}
      >
        <label
          id="log_lab_lastname"
          style={{
            display: "none",
            border: "none",
            color: "#0B6AEA",
            marginLeft: "12px",
            position: "absolute",
            marginTop: "-7px",
            //zIndex: 1,
            fontSize: "12px",
            padding: "0 8px",
            background: "white",
            fontFamily: "Inter",
            fontWeight: 400,
            lineHeight: "15px",
            wordWrap: "break-word",
          }}
        >
          Last Name
        </label>
        <input
          className="login_btn_hover"
          style={{
            marginBottom: 0,
            padding: "12px 20px",
            alignSelf: "stretch",
            height: "57px",
            paddingTop: "16px",
            paddingBottom: "16px",
            background: "white",
            borderRadius: "8px",
            border: "1px #E2EBF3 solid",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            display: "flex",
          }}
          type="text"
          id="lastname"
          name="lastname"
          placeholder="Last Name"
          // tabIndex={2}
          value={formData.lastname}
          onChange={handleInputChange}
          onFocus={changeinputfield_focus}
          onBlur={changeinputfield_blur}
          onKeyPress={(e) => validateAlphabetsOnly(e)}
          required
          autoComplete="off"
        />
        <span
          style={{
            marginLeft: "28px",
            color: "#EB5757",
            fontSize: "12px",
            fontFamily: "Inter",
            fontWeight: 400,
            lineHeight: "15px",
            wordWrap: "break-word",
          }}
        ></span>
      </div>
      <span
        id="invalid_lastname"
        style={{
          display: "none",
          width: "100%",
          justifyContent: "start",
          color: "#EB5757",
          fontSize: "12px",
          fontFamily: "Inter",
          fontWeight: 400,
          lineHeight: "15px",
          wordWrap: "break-word",
        }}
      >
        Invalid Last Name
      </span>
    </div>
  );
};

const MobileNumberComponent = ({
  formData,
  handleInputChange,
  changeinputfield_ph_focus,
  changeinputfield_ph_blur,
}) => {
  const [mobileNumberFocused, setMobileNumberFocused] = useState(false);
  const [countryCodeFocused, setCountryCodeFocused] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState("+91");

  return (
    <div style={{ width: "100%", alignSelf: "stretch", display: "flex" }}>
      <div style={{ width: "100%" }}>
        <label
          className={`label ${mobileNumberFocused ? "focused" : ""}`}
          id="log_lab_mobileNumber"
          style={{
            display: "none",
            color: "#0B6AEA",
            marginLeft: "12px",
            position: "absolute",
            marginTop: "-7px",
            zIndex: 2,
            width: 110,
            fontSize: "12px",
            padding: "0 6px",
            background: "white",
            fontFamily: "Inter",
            fontWeight: 400,
            lineHeight: "15px",
            wordWrap: "break-word",
            marginRight: 5,
          }}
        >
          Mobile Number
        </label>
        <div
          className="col-md-12"
          id="log_ph_field"
          style={{
            alignSelf: "stretch",
            height: "57px",
            background: "white",
            borderRadius: "8px",
            border: "1px #E2EBF3 solid",
            zIndex: 1020,
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            display: "flex",
          }}
        >
          <div
            className="row"
            style={{
              width: "100%",
              margin: 0,
              padding: 0,
              display: "inline-flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "24%", height: 55 }}>
              <select
                className={`stop_default_input_prop select-input ${
                  countryCodeFocused ? "focused" : ""
                }`}
                style={{
                  width: "100%",
                  padding: "16px",
                  padddingLeft: "20px",
                  paddingRight: "20px",
                  margin: 0,
                  borderRadius: "8px",
                  border: "none",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  display: "flex",
                  overflow: "hidden",
                  wordBreak: "break-word",
                }}
                id="countryCode"
                name="countryCode"
                value={selectedCountryCode}
                onChange={(e) => setSelectedCountryCode(e.target.value)}
                onFocus={(e) => {
                  changeinputfield_ph_focus(e);
                  setCountryCodeFocused(true);
                }}
                onBlur={(e) => {
                  changeinputfield_ph_blur(e);
                  setCountryCodeFocused(false);
                }}
                required
              >
                <option value="+91">+91</option>
                {/* Add more country code options here */}
              </select>
            </div>
            <div
              style={{
                width: 1,
                padding: "0",
                margin: "10px 0 10px 0",
                border: "1px solid #E2EBF3",
              }}
            ></div>
            <div style={{ width: "70%" }}>
              <input
                className={`stop_default_input_prop input ${
                  mobileNumberFocused ? "focused" : ""
                }`}
                style={{
                  padding: "12px 20px",
                  alignSelf: "stretch",
                  height: "55px",
                  borderRadius: "8px",
                  border: "none",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  display: "flex",
                  marginBottom: 0,
                }}
                type="text"
                id="mobileNumber"
                name="mobileNumber"
                placeholder="Mobile Number"
                value={formData.mobileNumber}
                onChange={handleInputChange}
                onFocus={(e) => {
                  changeinputfield_ph_focus(e);
                  setMobileNumberFocused(true);
                }}
                onBlur={(e) => {
                  changeinputfield_ph_blur(e);
                  setMobileNumberFocused(false);
                }}
                required
                autoComplete="off"
              />
            </div>
          </div>
        </div>
        <span
          id="invalid_mobileNumber"
          style={{
            display: "none",
            width: "100%",
            justifyContent: "flex-start",
            textAlign: "left",
            alignItems: "flex-start",
            marginTop: "5px",
            color: "#EB5757",
            fontSize: "12px",
            fontFamily: "Inter",
            fontWeight: 400,
            lineHeight: "15px",
            wordWrap: "break-word",
          }}
        >
          Invalid Mobile Number
        </span>
      </div>
    </div>
  );
};

const PasswordComponent = ({
  formData,
  handleInputChange,
  changeinputfield_focus,
  changeinputfield_blur,
  isPasswordValid,
  passwordMatchError,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          marginTop: 0,
          width: "100%",
          alignSelf: "stretch",
          height: "57px",
          borderRadius: "8px",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-end",
          gap: "8px",
          display: "flex",
        }}
      >
        <div
          style={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "end",
            width: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              alignSelf: "stretch",
              height: "57px",
              borderRadius: "8px",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              display: "flex",
            }}
          >
            <label
              id="log_lab_password"
              style={{
                display: "none",
                color: "#0B6AEA",
                marginLeft: "12px",
                position: "absolute",
                marginTop: "-7px",
                zIndex: 1,
                fontSize: "12px",
                padding: "0 8px",
                background: "white",
                fontFamily: "Inter",
                fontWeight: 400,
                lineHeight: "15px",
                wordWrap: "break-word",
              }}
            >
              Password
            </label>
            <div
              style={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "end",
                width: "100%",
              }}
            >
              <input
                className="login_btn_hover"
                style={{
                  marginBottom: 0,
                  padding: "12px 20px",
                  height: "57px",
                  alignSelf: "stretch",
                  background: "white",
                  borderRadius: "12px",
                  fontFamily: showPassword ? "password" : "",
                  border: "1px #E2EBF3 solid",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  display: "inline-flex",
                }}
                type="text"
                id="password"
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleInputChange}
                onFocus={changeinputfield_focus}
                onBlur={changeinputfield_blur}
                required
                autoComplete="off"
              />
              <input
                type="button"
                onClick={togglePasswordVisibility}
                className={
                  !showPassword ? "password_hide_icon" : "password_show_icon"
                }
                id="password_icon"
                autoComplete="off"
                tabIndex="-1"
                style={{
                  width: "24px",
                  height: "24px",
                  appearance: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div id="invalid_password"></div>
      {!isPasswordValid && (
        <div
          className="error-message"
          style={{
            color: "#EB5757",
            textAlign: "left",
            fontSize: "12px",
            fontFamily: "Inter",
            fontWeight: "400",
            wordWrap: "break-word",
            width: "100%",
          }}
        >
          Must be at least 8 characters including special character, uppercase
          letter and a number
        </div>
      )}

      {/*    <TextField
        id="outlined-password-input"
        label="Password"
        type="password"
        name="password"
        onChange={handleInputChange}
        error={passwordMatchError}
        helperText={
          !isPasswordValid
            ? "Must be at least 8 characters including special character, uppercase letter and a number"
            : ""
        }
        size="medium"
        fullWidth
        autoComplete="current-password"
      /> */}
    </div>
  );
};

const SubmitButtonComponent = ({
  hilight,
  combinedClickHandler,
  formData,
  navigate,
  countryCode,
  passwordMatchError,
}) => {
  // const [selectedCountryCode, setSelectedCountryCode] = useState("+91");
  const nodeapiBaseUrl = process.env.REACT_APP_NODEJS_URL;
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [mobileNumberError, setMobileNumberError] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [timer, setTimer] = useState(30); // Set the initial countdown time

  const handleShowErrorModal = (errorMessage) => {
    setModalErrorMessage(errorMessage);
    setShowErrorModal(true);
  };

  const startTimer = () => {
    setResendDisabled(true);
    setTimer(60); // Reset timer to 30 seconds when clicked

    // Set up the interval to decrement timer
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 1) {
          clearInterval(interval);
          setResendDisabled(false); // Enable resend after countdown finishes
        }
        return prevTimer - 1;
      });
    }, 1000);
  };

  const handleSendOTP = async () => {
    if (!formData.mobileNumber || !countryCode) {
      setMobileNumberError(true);
      return;
    } else {
      setMobileNumberError(false);
    }

    const mobileNumberWithCountryCode = countryCode + formData.mobileNumber;

    localStorage.setItem("phoneNumber", mobileNumberWithCountryCode);

    startTimer(); // Start the timer when OTP is sent

    try {
      const response = await axios.post(
        `${nodeapiBaseUrl}:5003/api/send-sms-otp`,
        {
          phone: mobileNumberWithCountryCode, // Use profileData.mobileNumber
        }
      );

      setOtpSent(true);
    } catch (error) {
      handleShowErrorModal(error.message);
    }
  };

  const handleEmailSendOTP = async () => {
    localStorage.setItem("emailForOtp", formData.email);
    const email = formData.email;
    try {
      const response = await axios.post(
        `${nodeapiBaseUrl}:5003/api/send-email-otp`,
        {
          email: email,
        }
      );

      const result = true; // Assuming the server returns JSON
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  return (
    <div id="kc-form-buttons" style={{ width: "100%" }}>
      <button
        type="submit"
        className="btntext hgt46px"
        style={{
          backgroundColor: hilight ? "#0B6AEA" : "#E2EBF3",
          color: hilight ? "white" : "#4A5965",
          cursor: hilight ? "" : "not-allowed",
          display: "inline-flex",
        }}
        onClick={() => {
          combinedClickHandler();
          handleSendOTP();
          handleEmailSendOTP();
          navigate("/OtpVerification", { state: { formData } });
        }}
        disabled={!hilight || passwordMatchError}
      >
        Verify Email and Mobile Number
      </button>
    </div>
  );
};

export default SubmitButtonComponent;

export {
  FirstNameComponent,
  LastNameComponent,
  MobileNumberComponent,
  PasswordComponent,
  SubmitButtonComponent,
};
