import React from "react";
import {
  Profile_details_generalview,
  Profile_details_header,
  Profile_edit_view_footer,
  Profile_interest_edit_view,
} from "../Profile_components";
import add_details from "../../../assets/img/add_details.svg";
import delete_details from "../../../assets/img/delete_details.svg";
import dateFormat from "dateformat";
import Pencil from "../../../assets/img/Pencil.svg";
import "react-image-crop/dist/ReactCrop.css";
import EditCertificate from "./EditCertificate";
import { Field } from "formik";
import FormHelperText from "@mui/material/FormHelperText";
import Box from "@mui/material/Box";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import TextField from "@mui/material/TextField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AddCertificate from "./AddCertificate";
import { useState } from "react";

const Certificates = (props) => {


  const {
    profileData,
    setcert_action,
    deleteablebtnclick2,
    editablebtnclick2,
    cert_action,
    sectionRefs,
    cert_editid,
    handleEduEditDetailsClick,
    handleInputChange3,
    handledateInputChange3,
    discardfunction2,
    handlecertsubmit,
    saveCertificateDataToKeyCloak,
    saveEditedCertificateDataToKeyCloak,
    fieldWisePercentage,
  } = props;

  const [certificateDetails, setCertificateDetails] = useState({
    certCourseName: "",
    certProvider: "",
    certUrl: "",
    certDate: "",
    skillsAccquired: [],
  });

  const certificateSchema = Yup.object().shape({
    certCourseName: Yup.string().required("Course name is required"),
    certProvider: Yup.string().required(
      "Certificate provider name is required"
    ),
    certUrl: Yup.string().required("Certificate URL is required"),
    certDate: Yup.date()
      .required("Certificate Date is required")
      .max(new Date(), "Certificate date cannot be future date"),
    skillsAccquired: Yup.array()
      .of(Yup.string())
      .min(1, "Select atleast one skill"),
  });

  return (
    <div>
      {/* view start*/}
      {cert_action === "view" ? (
        <div
          ref={sectionRefs.certificateid}
          id="certificateid"
          className="education-view-container"
          style={{ marginBottom: "150px" }}
        >
          <Profile_details_header
            adddetailsbtn="yes"
            bottomborder={`${
              profileData.certificate.length ? "profilebottomborder" : ""
            }`}
            clickname={() => setcert_action("add")}
            imgtoshow={add_details}
            topic="Certifications"
            fieldWisePercentage={fieldWisePercentage.certificates}
          />
          <div className="education-list-container">
            {/*   {profileData.certificate.length === 0 && (
              <div
                style={{
                
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom:'120px'
                }}
              >
            
              </div>
            )} */}
            {profileData.certificate.map((item, index) => (
              <div className="education-list">
                <div className="certificate-flex-container">
                  <div className="certificate-cards-container">
                    <Profile_details_generalview
                      heading={item.certProvider}
                     
                      deletebtnclick={() => deleteablebtnclick2(index)}
                      editbtnclick={() => editablebtnclick2(index)}
                      description={
                        item.certCourseName +
                        " " +
                        "-" +
                        " " +
                        dateFormat(item.certification_date, "yyyy")
                      }
                      editicon={Pencil}
                      deleteicon={delete_details}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}

      {cert_action === "edit" &&
        profileData.certificate &&
        profileData.certificate.map((item, index) =>
          index === cert_editid ? (
            <div
              ref={sectionRefs.certificateid}
              id="certificateid"
              className=""
            >
              <Formik
                initialValues={item}
                validationSchema={certificateSchema}
                onSubmit={(val) => {
                  saveEditedCertificateDataToKeyCloak(val, index);
                
                  setcert_action("view");
                }}
              >
                {({ values, errors, handleChange, handleSubmit, touched }) => (
                  <Form style={{ width: "100%" }}>
                    <div className="work-experience-view-container">
                      <Profile_details_header
                        bottomborder="profilebottomborder"
                        clickname={handleEduEditDetailsClick}
                        imgtoshow={add_details}
                        topic="Certificates"
                      />
                      <EditCertificate
                        certificateDetails={item}
                        setCertificateDetails={setCertificateDetails}
                        values={item}
                        errors={errors}
                        handleChange={handleChange}
                        discard_editview={discardfunction2}
                        touched={touched}
                      />
                      {/*   <Profile_edit_view_footer
                        clickingsubmitbtn={handleSubmit}
                        discard_editview={discardfunction2}
                      /> */}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          ) : (
            ""
          )
        )}

      {cert_action === "add" ? (
        <div
          ref={sectionRefs.certificateid}
          id="certificateid"
          style={{ marginBottom: "350px", }}
          className="education-add-container"
        >
          <Profile_details_header
            bottomborder="profilebottomborder"
            imgtoshow={add_details}
            topic="Certificate Details"
            propData={profileData}
          />

          {/*  <Profile_interest_edit_view
            mydata={emptydata2}
            valuechange={checkingvalues}
            functionnamecall={handlecertdata}
            changedate={handleadddateInputChange3}
          /> */}
          <Formik
            initialValues={{
              certCourseName: "",
              certProvider: "",
              certUrl: "",
              certDate: "",
              skillsAccquired: [],
            }}
            validationSchema={certificateSchema}
            onSubmit={(val) => {
              saveCertificateDataToKeyCloak(val);
          
              setcert_action("view");
            }}
          >
            {({ values, errors, handleChange, handleSubmit, touched }) => (
              <Form style={{ width: "100%" }}>
                <AddCertificate
                  certificateDetails={certificateDetails}
                  setCertificateDetails={setCertificateDetails}
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                  discard_editview={discardfunction2}
                  touched={touched}
                />
                {/* <Profile_edit_view_footer
                  //clickingsubmitbtn={handlenewcertsubmit}
                  discard_editview={discardfunction2}
                  handleSubmit={handleSubmit}
                /> */}
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default Certificates;
