import Footer from "../Footer";
import Headers from "../Headers";
import Navpages from "../Navpages";
import Aboutsections from "./Aboutsections";


function About()  {
    return (
      <div>

       <Headers/>
        <Navpages/>
        <Aboutsections/>
        <Footer/>
        

        </div>
    );
  }
  
  export default About;