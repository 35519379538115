import React from 'react';
import { useNavigate } from 'react-router-dom';
import Keycloak_config from '../services/Keycloak_config';
import UserService from '../services/UserService';
 
const Logout = () => {
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const keycloakRealmName = process.env.KEYCLOAK_PROD_REALM;
  const keycloakClientId = process.env.KEYCLOAK_PROD_CLIENT_ID;
 
  const handleLogout = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
 
    if (true) {
      try {
        // Logout from Keycloak
        const keycloakResponse = await fetch(`${apiBaseUrl}/realms/${process.env.REACT_APP_KEYCLOAK_REALM_NAME}/protocol/openid-connect/logout`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: `client_id=admin_cli&client_secret=${Keycloak_config.client_secret}&refresh_token=${refreshToken}`,
        });
 
        if (keycloakResponse.ok) {
          // Clear user data, tokens, and refresh token from local storage
          localStorage.removeItem('refreshToken');
          localStorage.removeItem('phoneNumber')
          localStorage.removeItem('emailForOtp')
          localStorage.removeItem('AWSS3Key')
          localStorage.removeItem('imageURL')
          localStorage.removeItem('fullname')
          localStorage.removeItem('kc-callback-03ce51dd-c166-4088-b0b3-3bba3d462f4c')
          localStorage.removeItem('hasShownDeadlinePopup')
 
          // Logout from Moodle
          const moodleLogoutUrl = `${process.env.REACT_APP_MOODLE_URL}/auth/oidc/logout.php`; // replace with your Moodle logout URL
          const moodleResponse = await fetch(moodleLogoutUrl, {
            method: 'GET',
            credentials: 'include', // Ensure cookies are sent with the request
          });
          UserService.doLogout({ redirectUri: process.env.REACT_APP_FRONTEND_URL });
          if (moodleResponse.ok) {
            navigate('/');
          } else {
            console.error('Moodle logout failed');
            navigate('/');
          }
        } else {
          console.error('Keycloak logout failed');
          navigate('/');
        }
      } catch (error) {
        console.error('Error during logout:', error);
        navigate('/');
      }
    } else {
      console.error('Refresh Token not found');
      navigate('/');
    }
  };
 
  return (
    <div>
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
};
 
export default Logout;