import UFOImage from "../../assets/Beep_Beep_UFO.svg";
import UFOMask from "../../assets/ufoMask.svg";
import PassTick from "../../assets/tick_Icon.svg";
import FailCross from "../../assets/fail_cross.svg";
import StarImage from "../../assets/star.svg";
import FlagImage from "../../assets/flag.svg";
import Styles from "./assessment.module.css";
import axios from "axios";
import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import UserService from "../../services/UserService";

const AssessmentReportPage = () => {
  const nodeApiBaseUrl = process.env.REACT_APP_NODEJS_TS;
  console.log(nodeApiBaseUrl, "check");
  const email = UserService.getUsername();
  const [assessmentData, setAssessmentData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.post(`${nodeApiBaseUrl}/grades`, {
        email: email,
        courseId: process.env.REACT_APP_GRADES_PAYLOAD,
      });
      setAssessmentData(response.data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }, [email, nodeApiBaseUrl]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error...</p>;

  const gradeData = assessmentData.finalScores;
  const result = assessmentData.userDetail.quizProgress;
  return (
    <div className={Styles.container}>
      <div className={Styles.sectionOne}>
        <div className={Styles.innerSectionOne}>
          <div className={Styles.imageWrapper}>
            <img
              src={UFOMask}
              alt="mask"
              className={Styles.sectionOneMaskImage}
            />
            <img
              src={UFOImage}
              alt="stem assessment"
              className={Styles.sectionOneImage}
            />
          </div>
          <div className={Styles.textWrapper}>
            <h5 className={Styles.sectionOneHeading}>STEM Assessment Report</h5>
            <span className={Styles.sectionOneParagraph}>{`${
              result === "Pass"
                ? "Congratulations on successfully clearing the Assessment."
                : "Mistakes are part of learning journey. You can reattempt in 60 days"
            }`}</span>
          </div>
        </div>
        <div className={Styles.divider}></div>

        <div className={Styles.innerSectionTwo}>
          <div className={Styles.gradeBox}>
            <p className={Styles.finalGrade}>FINAL GRADE</p>
            <div className={Styles.gradeContent}>
              {result === "Pass" ? (
                <img src={PassTick} alt="tick" className={Styles.gradeImage} />
              ) : (
                <img
                  src={FailCross}
                  alt="cross"
                  className={Styles.gradeImage}
                />
              )}
              <h4 className={Styles.gradeText}>
                {result === "Pass" ? "Pass" : "Fail"}
              </h4>
            </div>
          </div>
          <div className={Styles.infoBoxWrapper}>
            <div className={Styles.infoBox}>
              <p className={Styles.infoText}>CORRECT ANSWERS</p>
              <h6
                className={Styles.infoValue}
              >{`${assessmentData.overall[0].correctQuestions}/${assessmentData.overall[0].overallQuestions}`}</h6>
            </div>
            <div className={Styles.infoBoxTwo}>
              <p className={Styles.infoText}>FINAL SCORE</p>
              <h6 className={Styles.infoValue}>
                {assessmentData.overall[0].mark}
              </h6>
            </div>
          </div>
        </div>
        <div className={Styles.relativeWrapper}>
          <div className={Styles.overlayBoxOne}></div>
          <div className={Styles.overlayBoxTwo}></div>
        </div>
      </div>
      <div className={Styles.sectionTwo}>
        <h5 className={Styles.summaryHeading}>Summary Of Your Attempt</h5>
        <div className={Styles.tableContainer}>
          <table className={Styles.table}>
            <tr className={Styles.tableHeaderRow}>
              <th className={Styles.tableHeader}>Test Part</th>
              <th className={Styles.tableHeader}>Grade</th>
              <th className={Styles.tableHeader}>Correct Answers</th>
              <th className={Styles.tableHeader}>Score</th>
            </tr>
            {gradeData.map((data, index) => (
              <tr key={index} className={Styles.tableRow}>
                <td className={Styles.tableDataTest}>
                  <span className={Styles.tableTestText}>{data.quizName}</span>
                </td>
                <td className={Styles.tableData}>
                  <span
                    className={
                      data.results === "Pass"
                        ? Styles.tableResultPass
                        : Styles.tableResultFail
                    }
                  >
                    {data.results === "Pass" ? "Pass" : "Fail"}
                  </span>
                </td>
                <td className={Styles.tableData}>
                  <span className={Styles.tableGradeText}>
                    {data.correctCount}/{data.totalQuestions}
                  </span>
                </td>
                <td className={Styles.tableData}>
                  <span className={Styles.tableGradeText}>
                    {data.gradeScored}/{data.totalGrade}
                  </span>
                </td>
              </tr>
            ))}
          </table>
        </div>
        <div className={Styles.buttonWrapper}>
          <div>
            <Link to="/" className={Styles.linkStyle}>
              <button className={Styles.button}>
                <span className={Styles.buttonText}>Back To Homepage</span>
              </button>
            </Link>
          </div>
          <div className={Styles.reportSection}>
            <div>
              <img src={StarImage} alt="star" className={Styles.reportIcons} />
              <span className={Styles.feedbackText}>Give Us Feeback</span>
            </div>
            <div>
              <img src={FlagImage} alt="flag" className={Styles.reportIcons} />
              <span className={Styles.reportText}>Report An Issue</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentReportPage;
