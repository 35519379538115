import { useState, useRef, useCallback, useEffect } from "react";
import UserService from "../../../services/UserService";
import Styles from "./assignment.module.css";
import UFOMask from "../../../assets/img/ufoMask.svg";
import UFOImage from "../../../assets/img/Beep_Beep_UFO.svg";
import Add_Icon from "../../../assets/img/Add_Icon.svg";
import { Accordion } from "./Accordion";
import axios from "axios";

const AssignmentDownloadPage = () => {
  const [selectedFileName, setSelectedFileName] = useState("");
  const [text, setText] = useState("");
  const [file, setFile] = useState(null);
  const [invalidFileFormat, setInvalidFileFormat] = useState(false);
  const focusSubmissions = useRef(null);
  const fileInputRef = useRef(null);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [charCount, setCharCount] = useState(0);
  const [assignmentData, setAssignmentData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [files, setFiles] = useState([]);
  const [name, setname] = useState("");
  const [url, seturl] = useState("");
  const [timeModified, settimeModified] = useState("");
  const [onlinetext, setonlinetext] = useState("");
  const nodeapiBaseUrl = process.env.REACT_APP_NODEJS_TS;

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.post(
        `${nodeapiBaseUrl}/get_assign_details`,
        {
          courseId: process.env.REACT_APP_FILE_COURSE_ID,
        }
      );
      setAssignmentData(response.data);

      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }, [`${nodeapiBaseUrl}/get_assign_details`]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const userEmail = UserService.getUsername();
        const response = await axios.post(`${nodeapiBaseUrl}/file`,
          {

            courseId: process.env.REACT_APP_FILE_COURSE_ID,
            email: userEmail,

          });
        var { name } = response.data;
        var { url } = response.data;
        var { timeModified } = response.data;
        var { onlinetext } = response.data;
        setname(name);
        seturl(url);
        settimeModified(timeModified);
        setonlinetext(onlinetext);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchFiles();

    // Cleanup function (optional)
    return () => {
      // Cleanup tasks if needed
    };
  }, []);

  const handleDownload = () => {
    // Triggering file download by creating an anchor element
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.download = '';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error...</p>;


  const items = [
    {
      title: "Instructions",
      content: "Content for Item 1",
    },
  ];

  let instructions = assignmentData.instruction;
  let brief = assignmentData.brief;
  let description = assignmentData.description;


  return (
    <div className={Styles.container}>
      <div className={Styles.assignmentHeader}>
        <div className={Styles.textWrapper}>
          <div className={Styles.imageWrapperMob}>
            <img
              src={UFOMask}
              alt="mask"
              className={Styles.sectionOneMaskImageMob}
            />
            <img
              src={UFOImage}
              alt="stem assessment"
              className={Styles.sectionOneImageMob}
            />
          </div>
          <p className={Styles.headerParaOne}>ASSIGNMENT</p>
          <div className={Styles.innerTextWrapper}>
            <h4 className={Styles.innerTextHead}>
              {assignmentData.name}
              {/* Automotive Awareness Assignment */}
            </h4>
            <p className={Styles.headerParaTwo}>
              {description}
              {/* The intent of this awareness course is to help the students
              understand all that is needed to know about the industry in which
              they will work in the future and progress their career. */}
            </p>
          </div>
        </div>
        <div className={Styles.headerSubmission}>
          <button
            className={Styles.headerButton}
            onClick={() => focusSubmissions.current?.scrollIntoView()}
          >
            <p className={Styles.buttonText}>Completed</p>
          </button>
          <p4 className={Styles.headerParaThree}>
            Submitted on:
            <span className={Styles.headerParaThreeSpan}>
              {' ' + timeModified}
            </span>
          </p4>
        </div>
        <div className={Styles.relativeWrapper}>
          <div className={Styles.overlayBoxOne}></div>
          <div className={Styles.overlayBoxTwo}></div>
        </div>
        <div className={Styles.imageWrapper}>
          <img
            src={UFOMask}
            alt="mask"
            className={Styles.sectionOneMaskImage}
          />
          <img
            src={UFOImage}
            alt="stem assessment"
            className={Styles.sectionOneImage}
          />
        </div>
      </div>
      <Accordion instructions={instructions} items={items} />
      <div className={Styles.assignmentBrief}>
        <p className={Styles.briefTextOne}>Assignment Brief</p>
        <p className={Styles.briefTextTwo} dangerouslySetInnerHTML={{ __html: brief }}>
          {/* The STEM Assessment will give you a comprehensive snapshot of your
          current UX/UI design skills, including your design thinking ability
          and your knowledge of the psychology of design, UX research, and
          design methodologies. You’ll get learning recommendations based on
          your strengths and weaknesses and see how you stack up against other
          designers. */}
        </p>
      </div>
      <div className={Styles.assignmentSubmission} ref={focusSubmissions}>
        <p className={Styles.submissionTextOne}>My Submission</p>
        <p className={Styles.summary}>Summary</p>
        <div className={Styles.textAreaWrapper}>
          {onlinetext}
        </div>
        <div className={Styles.fileUpload}>
          <p className={Styles.submissionTextTwo}>Your File</p>
          <div style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 16, display: 'inline-flex' }}>
            <button onClick={handleDownload} style={{ borderStyle: 'none', borderColor: 'white', backgroundColor: 'white', color: '#0B6AEA', fontSize: 14, fontFamily: 'Inter', fontWeight: '400', textDecoration: 'underline', wordWrap: 'break-word' }}>{name || 'Loading...'}</button>
            <div style={{ paddingTop: 4.21, paddingBottom: 3.79, paddingLeft: 4, paddingRight: 4, background: '#E2EBF3', borderRadius: 19.20, overflow: 'hidden', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
              <div style={{ cursor: 'pointer', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 4, display: 'inline-flex' }}>
                <img src={Add_Icon} alt="Download" onClick={handleDownload} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignmentDownloadPage;
