import React from 'react';
import { Modal } from 'react-bootstrap';
import Triangle_frame from '../../../assets/img/Triangle_frame.svg';
import { Link } from 'react-router-dom';
import "./ProfileCompletionPopUp.css";

const ProfileCompletionPopUp = ({ personalDetailsPercentage, educationalDetailsPercentage, technicalDetailsPercentage, showModal }) => {
    let show = showModal;
    const handleClose = () => { show = false };

    return (
        <>
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} style={{ zIndex: 3001 }}>
                <div className="modal-dialog-centered">

                    <div >
                        <div style={{ borderRadius: '20 !important' }}>
                            <div className='popup_padding' style={{ width: '100%', height: '100%', background: 'white', boxShadow: '0px 8px 40px rgba(9, 44, 76, 0.08)', borderRadius: 12, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', display: 'inline-flex' }}>
                                <div className='popup_outerlayer' >
                                    <div style={{ width: 76, height: 77.21, position: 'relative' }}>
                                        <img src={Triangle_frame} alt='Warning' />
                                    </div>
                                    <div style={{ flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 16, display: 'inline-flex' }}>
                                        <div style={{ alignSelf: 'stretch', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 4, display: 'flex' }}>
                                            <div className='popup_heading' style={{ color: '#00213D', fontSize: 20, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word' }}>Before We Begin</div>
                                            <div className='popup_description' style={{ alignSelf: 'stretch', color: '#4A5965', fontSize: 14, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word' }}>Please provide us the below details. This will help us tailor the test accordingly</div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ alignSelf: 'stretch', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 28, display: 'flex' }}>
                                    <div style={{ alignSelf: 'stretch', justifyContent: 'center', alignItems: 'center', gap: 4, display: 'inline-flex' }}>
                                        <div style={{ flex: '1 1 0', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 4, display: 'inline-flex' }}>
                                            <div style={{ alignSelf: 'stretch', color: '#00213D', fontSize: 14, fontFamily: 'Inter', fontWeight: '600', wordWrap: 'break-word' }}>Personal Details</div>
                                            <div style={{ alignSelf: 'stretch', color: '#4A5965', fontSize: 12, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word' }}>{personalDetailsPercentage}</div>
                                        </div>
                                        <div style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 8, display: 'flex' }}>
                                            <Link to="/dashboard/profile?scrollTo=personalid">
                                                <button style={{ border: 'none', backgroundColor: 'white', padding: 0, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 4, display: 'inline-flex' }} >
                                                    <div style={{ color: '#0B6AEA', fontSize: 14, fontFamily: 'Inter', fontWeight: '600', wordWrap: 'break-word' }}>Add Details</div>
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div style={{ alignSelf: 'stretch', justifyContent: 'center', alignItems: 'center', gap: 4, display: 'inline-flex' }}>
                                        <div style={{ flex: '1 1 0', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 4, display: 'inline-flex' }}>
                                            <div style={{ alignSelf: 'stretch', color: '#00213D', fontSize: 14, fontFamily: 'Inter', fontWeight: '600', wordWrap: 'break-word' }}>Educational </div>
                                            <div style={{ alignSelf: 'stretch', color: '#4A5965', fontSize: 12, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word' }}>{educationalDetailsPercentage}</div>
                                        </div>
                                        <div style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 8, display: 'flex' }}>
                                            <Link to="/dashboard/profile?scrollTo=educationid">
                                                <button style={{ border: 'none', padding: 0, backgroundColor: 'white', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 4, display: 'inline-flex' }}>
                                                    <div style={{ color: '#0B6AEA', fontSize: 14, fontFamily: 'Inter', fontWeight: '600', wordWrap: 'break-word' }}>Add Details</div>
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div style={{ alignSelf: 'stretch', justifyContent: 'center', alignItems: 'center', gap: 4, display: 'inline-flex' }}>
                                        <div style={{ flex: '1 1 0', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 4, display: 'inline-flex' }}>
                                            <div style={{ alignSelf: 'stretch', color: '#00213D', fontSize: 14, fontFamily: 'Inter', fontWeight: '600', wordWrap: 'break-word' }}>Technical Competencies</div>
                                            <div style={{ alignSelf: 'stretch', color: '#4A5965', fontSize: 12, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word' }}>{technicalDetailsPercentage}</div>
                                        </div>
                                        <div style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 8, display: 'flex' }}>
                                            <Link to="/dashboard/profile?scrollTo=interestid">
                                                <button style={{ border: 'none', padding: 0, backgroundColor: 'white', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 4, display: 'inline-flex' }}>
                                                    <div style={{ color: '#0B6AEA', fontSize: 14, fontFamily: 'Inter', fontWeight: '600', wordWrap: 'break-word' }}>Add Details</div>
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Modal >
        </>
    );
};

export default ProfileCompletionPopUp;
