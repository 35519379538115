import { useEffect } from "react";
import {
  Profile_details_header,
  Profile_edit_view_footer,
  TechnicalCompetencies,
  TechnicalCompetenciesSelected,
} from "../Profile_components";

import add_details from "../../../assets/img/add_details.svg";

import "../profile.module.css";

const TechnicalCompetenciesSection = (props) => {
  const {
    skillSectionEditState,
    sectionRefs,
    profileData,
    handleSkillEditState,
    saveSkillData,
    returnbackskillsnormal,
    fieldWisePercentage,
  } = props;

  const dropDownApiCall = async () => {
    const res = await fetch(
      `${process.env.REACT_APP_NODEJS_TS}/get-profile-data`
    );
    const data = await res.json();
    return data;
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await dropDownApiCall();
      console.log(data);
      localStorage.setItem("skillsData", JSON.stringify(data.skills));
    };

    fetchData();
  }, []);

  return skillSectionEditState ? (
    <div
      ref={sectionRefs.interestid}
      id="interestid"
      className="technical-container"
    >
      <Profile_details_header
        bottomborder={`${
          profileData.technicalSkills.length ? "profilebottomborder" : ""
        }`}
        clickname={handleSkillEditState}
        imgtoshow={add_details}
        topic="Technical Competencies"
        subTopic="Maximum 7 Skills can be added"
      />

      <TechnicalCompetencies
        saveSkillData={saveSkillData}
        discardSkills={returnbackskillsnormal}
        selectedSkill={profileData.technicalSkills}
        toggleSkillSection={handleSkillEditState}
      />
    </div>
  ) : (
    <div
      ref={sectionRefs.interestid}
      id="interestid"
      className="technical-container-view"
    >
      <Profile_details_header
        bottomborder={`${
          profileData.technicalSkills.length ? "profilebottomborder" : ""
        }`}
        adddetailsbtn="yes"
        clickname={handleSkillEditState}
        imgtoshow={add_details}
        topic="Technical Competencies"
        fieldWisePercentage={fieldWisePercentage.technicalSkills}
      />

      <TechnicalCompetenciesSelected
        selectedSkill={profileData.technicalSkills}
        className="green"
      />
    </div>
  );
};

export default TechnicalCompetenciesSection;
