import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { FormLabel, RadioGroup, Radio } from "@mui/material";

import { Field } from "formik";
import Styles from "../profile.module.css";
import { useEffect, useState } from "react";

import FormControlLabel from "@mui/material/FormControlLabel";

const CollegeAcademics = (props) => {
  const {
    eduDetails,
    setEduDetails,
    values,
    errors,
    touched,
    handleChange,
    setErrors,
    education_action,
    seteducation_action,
    profileData,
    discardfunction3,
  } = props;

  const specializations = JSON.parse(localStorage.getItem('branchData'))

  const [collegeNameError, setCollegeNameError] = useState("");
  const [courseTypeError, setCourseTypeError] = useState("");
  const [specalizationsError, setSpecalizationsError] = useState("");
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const [gradingTypeError, setGradingTypeError] = useState("");
  const [collegeCGPAError, setCollegeCGPAError] = useState("");
  const [collegePercentageError, setCollegePercentageError] = useState("");
  const [courseModeError, setCourseModeTypeError] = useState("");

  const [startDateFlag, setStartDateFlag] = useState(false);
  const [endDateFlag, setEndDateFlag] = useState(false);
  const [collegeCGPAFlag, setCollegeCGPAFlag] = useState(false);
  const [collegePercentageFlag, setCollegePercentageFlag] = useState(false);

  useEffect(() => {
    setErrors({});
    //setEduDetails({})
  }, [eduDetails.educationLevel]);

  const setEducationLevel = (e) => {
    // setErrors({})
    const selectedVal = e.target.value;
    values.educationLevel = selectedVal;
    setEduDetails({ ...eduDetails, educationLevel: selectedVal });
  };

  const setCollegeName = (e) => {
    const selectedVal = e.target.value;
    if (e.target.value.charAt(0) === " ") {
      e.target.value = e.target.value.trim();
    }

    if (!selectedVal) {
      setCollegeNameError("College Name is required");
    } else if (
      !/^[a-zA-Z ]{0,100}$/.test(selectedVal) ||
      selectedVal.startsWith(" ")
    ) {
      setCollegeNameError("Enter Valid college name ");
      e.target.value = selectedVal.replace(/[^a-zA-Z\s]/g, "");
    } else {
      setCollegeNameError("");
      values.collegeName = selectedVal.trim();
      setEduDetails({ ...eduDetails, collegeName: selectedVal.trim() });
      setErrors({ ...errors, collegeName: "" });
    }
    handleChange(e);
  };

  const setCourses = (e) => {
    const selectedVal = e.target.value;
    values.courses = selectedVal;
    setEduDetails({ ...eduDetails, courses: selectedVal });
    handleChange(e);
    if (!selectedVal) {
      setCourseTypeError("Course is required");
      errors.courses = "Course is required";
    } else {
      setCourseTypeError("");
      setErrors({ ...errors, courses: "" });
    }
  };

  const setSpecalizations = (e) => {
    const selectedVal = e.target.value;
    values.specalizations = selectedVal;
    setEduDetails({ ...eduDetails, specalizations: selectedVal });
    handleChange(e);
    if (!selectedVal) {
      setSpecalizationsError("Specalizations is required");
    } else {
      setSpecalizationsError("");
      setErrors({ ...errors, specalizations: "" });
    }
  };

  const setStartDate = (date) => {
    const selectedVal = date.target.value;
    setEduDetails({ ...eduDetails, startDate: selectedVal });
    values.collegeStartDate = date;
    handleChange(date);
    if (!selectedVal) {
      setStartDateError("Start date is required");
      setStartDateFlag(true);
    } else if (new Date(selectedVal) >= new Date()) {
      setStartDateError("Start date cannot be a future date");
      setStartDateFlag(true);
    } else {
      setStartDateError("");
      setStartDateFlag(false);
      setErrors({ ...errors, collegeStartDate: "" });
    }
  };

  const setEndDate = (date) => {
    const selectedVal = date.target.value;
    if (!selectedVal) {
      setEndDateError("End Date / Tentative Date is required");
      setEndDateFlag(true);
    } else if (new Date(selectedVal) <= new Date(values.collegeStartDate)) {
      setEndDateError(" End Date cannot be before start date ");
      setEndDateFlag(true);
    } else {
      setEndDateError("");
      setEduDetails({ ...eduDetails, endDate: selectedVal });
      setEndDateFlag(false);
      values.collegeEndDate = date;
      setErrors({ ...errors, collegeEndDate: "" });
    }
    handleChange(date);
  };

  const setGradingType = (e) => {
    const selectedVal = e.target.value;
    values.gradingType = selectedVal;
    values.percentage = "";
    values.collegeCGPA = "";
    setCollegePercentageError("");
    setCollegeCGPAError("");
    setErrors({
      ...errors,
      collegeGradeSystem: "",
      percentage: "",
      collegeCGPA: "",
    });

    setEduDetails({
      ...eduDetails,
      gradingType: selectedVal,
      percentage: "",
      collegeCGPA: "",
    });
    handleChange(e);
    if (!selectedVal) {
      setGradingTypeError("Required");
    } else {
      setGradingTypeError("");
    }
  };

  const setMarks = (e) => {
    const selectedVal = e.target.value;

    setCollegeCGPAError("");
    setCollegePercentageError("");
    setCollegeCGPAFlag(false);
    setCollegePercentageFlag(false);
    setErrors({
      ...errors,
      collegeGradeSystem: "",
      percentage: "",
      collegeCGPA: "",
    });

    if (e.target.name === "collegeCGPA") {
      setErrors({ ...errors, percentage: "" });
      if (!selectedVal) {
        setCollegeCGPAError("Required");
        setCollegeCGPAFlag(true);
        setErrors({ ...errors, percentage: "" });
      } else if (!/^([1-9](\.\d{1,2})?|10(\.0{1,2})?)$/.test(selectedVal)) {
        setCollegeCGPAError("Enter Valid CGPA from 1.0 to 10.0");
        setErrors({
          ...errors,
          collegeCGPA: "Enter Valid CGPA from 1.0 to 10.0",
          percentage: "",
        });
        e.target.value = selectedVal.replace(/[^0-9.]/g, ""); // Allow only digits and decimal points
        // Allow only one decimal point and ensure correct format
        const decimalIndex = e.target.value.indexOf(".");
        if (decimalIndex !== -1) {
          e.target.value =
            e.target.value.substring(0, decimalIndex + 1) +
            e.target.value.substring(decimalIndex + 1).replace(/\./g, "");
        }
        // Restrict the length to 4 characters (e.g., 10.0, 9.99)
        e.target.value = e.target.value.substring(0, 4);
        setCollegeCGPAFlag(true);
      } else {
        setCollegeCGPAError("");
        setCollegeCGPAFlag(false);
        setEduDetails({ ...eduDetails, marks: selectedVal });
        setErrors({ ...errors, collegeCGPA: "", percentage: "" });
      }
    }

    if (e.target.name === "percentage") {
      if (!selectedVal) {
        setCollegePercentageError("Required");
        setCollegePercentageFlag(true);
      } else if (
        !/^([1-9][0-9]?|100)$/.test(selectedVal) ||
        selectedVal.length > 3
      ) {
        e.target.value = selectedVal.replace(/[^0-9]$/, "");
        e.target.value = selectedVal.replace(/\D/g, "").substring(0, 3);
        setCollegePercentageError("Enter Valid percentage from 1 to 100");
        setCollegePercentageFlag(true);
      } else {
        setCollegePercentageError("");
        setEduDetails({ ...eduDetails, marks: selectedVal });
        setErrors({ ...errors, percentage: "", collegeCGPA: "" });
        setCollegeCGPAError("");
        setCollegeCGPAFlag(false);
        setCollegePercentageFlag(false);
      }
    }
    handleChange(e);
  };

  const setCourseType = (e) => {
    const selectedVal = e.target.value;
    values.courseType = e.target.value;
    setEduDetails({ ...eduDetails, courseType: e.target.value });
    handleChange(e);
    if (!selectedVal) {
      setCourseModeTypeError("Course Mode is required");
    } else {
      setCourseModeTypeError("");
      setErrors({ ...errors, courseType: "" });
    }
  };

  let educationLevels = ["Graduation", "Class XII", "Class X"];

  const existingEducationLevels = profileData.educationaldetails.map(
    (detail) => detail.educationLevel
  );

  const filteredEducationLevels = educationLevels.filter(
    (level) => !existingEducationLevels.includes(level)
  );

  educationLevels =
    education_action === "edit" ? educationLevels : filteredEducationLevels;

  return (
    <div className={Styles.profile_input_section}>
      <div className={Styles.profile_section_row}>
        <div className={Styles.profile_element}>
          {/* <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Education</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="educationLevel"
              label="Education"
              onChange={setEducationLevel}
              value={values.educationLevel}
              disabled={education_action === 'edit'}
              sx={{
                borderRadius: "8px",
                border: '1px solid  #E2EBF3',
                backgroundColor: "#FFFFFF",
              }}
            >
              {educationLevels.map((each) => (
                <MenuItem key={each} value={each}>
                  {each}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}

          <FormControl fullWidth error={errors.educationLevel}>
            <InputLabel id="demo-simple-select-label">Education *</InputLabel>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Education * "
              name="educationLevel"
              value={eduDetails.educationLevel}
              variant="outlined"
              disabled={education_action === "edit"}
              placeholder={values.educationLevel}
              onChange={setEducationLevel}
            >
              {educationLevels.map((each) => (
                <MenuItem key={each} value={each}>
                  {each}
                </MenuItem>
              ))}
            </Select>

            <FormHelperText>{errors.educationLevel}</FormHelperText>
          </FormControl>
        </div>
        <div className={Styles.profile_element}>
          <Field
            fullWidth
            name="collegeName"
            as={TextField}
            id="outlined-basic"
            label="College Name *"
            sx={{
              borderRadius: "8px",
              backgroundColor: "#FFFFFF",
            }}
            variant="outlined"
            onChange={setCollegeName}
            value={values.collegeName}
            error={errors.collegeName}
            helperText={errors.collegeName}
          />
        </div>
      </div>
      <div className={Styles.profile_section_row}>
        <div className={Styles.profile_element}>
          <FormControl fullWidth error={errors.courses}>
            <InputLabel id="demo-simple-select-label">Courses * </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              sx={{
                borderRadius: "8px",
                backgroundColor: "#FFFFFF",
              }}
              label="Courses *"
              name="courses"
              onChange={setCourses}
              //error={courseTypeError}
              value={eduDetails.courses}
            >
              <MenuItem value={"B.E"}>B.E.</MenuItem>
              <MenuItem value={"B.Tech"}>B.Tech.</MenuItem>
            </Select>
            <FormHelperText>{errors.courses}</FormHelperText>
          </FormControl>
        </div>
        <div className={Styles.profile_element}>
          <Box>
            <FormControl fullWidth error={errors.specalizations}>
              <InputLabel id="demo-simple-select-label">
                Specialization *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="specalizations"
                label="Specialization *"
                value={values.specalizations}
                onChange={setSpecalizations}
                sx={{
                  borderRadius: "8px",
                  backgroundColor: "#FFFFFF",
                }}
                // error={!!specalizationsError}
              >
                {specializations.map((each) => (
                  <MenuItem key={each.branch} value={each.branch}>
                    {each.branch}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.specalizations}</FormHelperText>
            </FormControl>
          </Box>
        </div>
      </div>
      <div className={Styles.profile_section_row}>
        <div className={Styles.profile_element}>
          <Field
            name="collegeStartDate"
            as={TextField}
            type="date"
            fullWidth
            sx={{
              borderRadius: "8px",
              backgroundColor: "#FFFFFF",
            }}
            label="Start Date *"
            onChange={setStartDate}
            error={!!startDateError || errors.collegeStartDate}
            helperText={startDateError || errors.collegeStartDate}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className={Styles.profile_element}>
          <Field
            name="collegeEndDate"
            as={TextField}
            type="date"
            fullWidth
            label="End Date / Tentative End Date *"
            // disabled={eduDetails.isPursuing}
            onChange={setEndDate}
            sx={{
              borderRadius: "8px",
              backgroundColor: "#FFFFFF",
            }}
            error={endDateError || errors.collegeEndDate}
            helperText={endDateError || errors.collegeEndDate}
            InputLabelProps={{
              shrink: true,
            }}
          />

          {/*  <FormGroup>
            <FormControlLabel
              control={<Checkbox />}
              label="Currently Pursing"
              onChange={isPursuing}
            />
          </FormGroup> */}
        </div>
      </div>
      <div className={Styles.profile_section_row}>
        <div className={Styles.profile_element}>
          <Box>
            <FormControl
              fullWidth
              error={!!gradingTypeError || errors.collegeGradeSystem}
            >
              <InputLabel id="demo-simple-select-label">
                Grading System *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Grading System *"
                sx={{
                  borderRadius: "8px",
                  backgroundColor: "#FFFFFF",
                }}
                onChange={setGradingType}
                value={values.gradingType}
                name="collegeGradeSystem"
              >
                <MenuItem value={"Percentage"}>Percentage</MenuItem>
                <MenuItem value={"CGPA"}>CGPA</MenuItem>
              </Select>
              <FormHelperText>
                {gradingTypeError || errors.collegeGradeSystem}
              </FormHelperText>
            </FormControl>
          </Box>
        </div>
        <div className={Styles.profile_element}>
          {values.gradingType === "CGPA" && (
            <div className={[Styles.profile_element, Styles.checkel]}>
              <Field
                fullWidth
                name="collegeCGPA"
                as={TextField}
                id="outlined-basic"
                label="CGPA *"
                variant="outlined"
                onChange={setMarks}
                sx={{
                  borderRadius: "8px",
                  backgroundColor: "#FFFFFF",
                }}
                error={collegeCGPAError || errors.collegeCGPA}
                helperText={collegeCGPAError || errors.collegeCGPA}
                value={values.collegeCGPA}
              />
            </div>
          )}
          {values.gradingType === "Percentage" && (
            <div className={[Styles.profile_element, Styles.checkel]}>
              <Field
                fullWidth
                name="percentage"
                as={TextField}
                id="outlined-basic"
                label="Percentage *"
                variant="outlined"
                onChange={setMarks}
                sx={{
                  borderRadius: "8px",
                  backgroundColor: "#FFFFFF",
                }}
                error={collegePercentageError || errors.percentage}
                value={values.percentage}
                helperText={collegePercentageError || errors.percentage}
                //value={values.percentage}
              />
            </div>
          )}
        </div>
      </div>

      <div className={Styles.profile_section_row}>
        <div className={Styles.profile_input_section}>
          <div>
            <FormControl error={courseModeError || errors.courseType}>
              <FormLabel id="demo-error-radios">Course Type *</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="courseType"
                onChange={setCourseType}
                style={{ gap: 32 }}
                value={values.courseType}
              >
                <FormControlLabel
                  value="Full Time"
                  control={<Radio />}
                  label="Full Time"
                />
                <FormControlLabel
                  value="Part Time"
                  control={<Radio />}
                  label="Part time"
                />
                <FormControlLabel
                  value="Correspondence/Distance learning"
                  control={<Radio />}
                  label="Correspondence/Distance learning"
                />
              </RadioGroup>
              <FormHelperText>
                {courseModeError || errors.courseType}
              </FormHelperText>
            </FormControl>
          </div>
        </div>
      </div>
      <div
        style={{
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 24,
          display: "inline-flex",
        }}
      >
        <button
          style={{
            paddingLeft: 24,
            paddingRight: 24,
            paddingTop: 12,
            paddingBottom: 12,
            background: "#0B6AEA",
            borderRadius: 8,
            justifyContent: "center",
            alignItems: "center",
            border: "none",
            display: "flex",
          }}
          disabled={
            collegeNameError ||
            startDateFlag ||
            endDateFlag ||
            collegeCGPAFlag ||
            collegePercentageFlag
          }
          type="submit"
        >
          <div className="save-changes-btn">Save Changes</div>
        </button>

        <button
          className="discard-btn"
          onClick={() => {
            setEduDetails({});
            discardfunction3();
          }}
        >
          <div className="discard-btn">Discard Changes</div>
        </button>
        {/*    <button
          type="button"
          onClick={() => {
            console.log(
              startDateFlag,
              endDateFlag,
              collegeCGPAFlag,
              collegePercentageFlag,
              "; startDateFlag, endDateFlag, collegeCGPAFlag, collegePercentageFlag"
            );
          }}
        >
          C H E C K F L A G
        </button>  */}
      </div>
    </div>
  );
};

export default CollegeAcademics;
