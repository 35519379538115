import React from 'react';
import Chart from 'react-apexcharts';
import './RoleRecommendationPage.css';

class KnowledgeChart extends React.Component {
    constructor(props) {
        super(props);
        // Determine the highest value in the data
        const maxValue = Math.max(...this.props.data);
        const maxIndex = this.props.data.indexOf(maxValue);

        this.state = {
            options: {
                chart: {
                    type: 'radar',
                    height: 550,
                },
                xaxis: {
                    categories: this.props.categories,
                    labels: {
                        style: {
                            fontSize: '14px',
                            fontWeight: this.props.categories.map((category, index) => {
                                return index === maxIndex ? 'bold' : '400';
                            }),
                            colors: this.props.categories.map((category, index) => {
                                return index === maxIndex ? '#239F57' : '#4A5965';
                            })
                        },
                        formatter: (value) => {
                            const { categories, data } = this.props;
                            const index = categories.indexOf(value);
                            const percentage = `${data[index]}%`;
                            const category = `${categories[index]}`;
                            return `${category} ${percentage}`;
                        }
                    }
                },
                yaxis: {
                    show: false,
                    min: 0,
                    max: 100,
                    tickAmount: 3,
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true
                    }
                },
                markers: {
                    size: 0,
                },
                fill: {
                    opacity: 0.5,
                    colors: ['#007bff']
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['#007bff']
                },
                dataLabels: {
                    enabled: false,
                },
                plotOptions: {
                    radar: {
                        polygons: {
                            strokeColor: '#E2EBF3',
                        }
                    }
                },
            },
        };
    }

    render() {
        const { data } = this.props;

        return (
            <div className="radar-chart-knowledge">
                <Chart
                    options={this.state.options}
                    series={[{ name: 'Series 1', data }]}
                    type="radar"
                    height={550}
                />
            </div>
        );
    }
}

export default KnowledgeChart;
