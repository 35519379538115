import { TopicSubcomponent, ModulesSubcomponent } from "./Listoffunctions";
import React, { useEffect, useState } from "react";

import "./journey.css";

import UserService from "../../../services/UserService";
import axios from "axios";
import { name } from "tar/lib/types";
import oidcConfig from "./oidcconfic";
import Automotive_Course_Icon from "../../../assets/img/Automotive_Course_Icon.svg";
import Journey from "./Jounney";
import Group from "../../../assets/img/Group.svg";
import { NavLink } from "react-router-dom";
import CdDesktop from "./CdDesktop";
import CdMobile from "./CdMobile";
import SubmissionTimer from "./SubmissionTimer";
import { useLocation } from "react-router-dom";

function Describing() {
  const nodeapiBaseUrl = process.env.REACT_APP_NODEJS_TS;
  const [moodleSSOUrl, setMoodleSSOUrl] = useState("");
  const [oidcLoginUrl, setOidcLoginUrl] = useState("");

  const Valuesof_TopicSubcomponent1 = {
    topic: "Automotive Industry Value Chain",
    description: "Video  |  10 min",
  };
  const Valuesof_TopicSubcomponent2 = {
    topic: "utomotive Industry Value Chain",
    description: "Video  |  20 min",
  };
  const Valuesof_TopicSubcomponent3 = {
    topic: "tomotive Industry Value Chain",
    description: "Video  |  30 min",
  };
  const [selectedTab, setSelectedTab] = useState("default");
  const [selectedContent, setContentChange] = useState();
  const [courses, setCourses] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openCourseId, setOpenCourseId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userCourses, setUserCourses] = useState([]);
  const [categories, setCategories] = useState([]);
  const email = UserService.getUsername();
  const [courseSections, setCourseSections] = useState({});
  const [course, setCourse] = useState(0);
  const [module, setModule] = useState([]);
  const [fullName1, setFullName1] = useState("");
  const [summary, setSummary] = useState("sefsefsdf");
  const [courseId, setCourseId] = useState([]);
  var content = "";
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [isWithinRange, setIsWithinRange] = useState(false);
  const [showSubmissionTimer, setShowSubmissionTimer] = useState(true);

  const extractTextFromParagraphs = (html) => {
    const paragraphs = html.match(/<p[^>]*>(.*?)<\/p>/gi);
    if (paragraphs) {
      return paragraphs.map(p => p.replace(/<[^>]*>?/gm, '')).join(' ');
    }
    return '';
  };

  const tabClickHandler = (tabName) => {
    switch (tabName) {
      case "Journey":
        content = <Journey />;
        break;
      default:
        setSelectedTab("default");
        break;
    }
    setSelectedTab(tabName);
    setContentChange(content);
  };

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const emailInput = {
          email: email,
        };
        const response = await axios.post(
          `${nodeapiBaseUrl}/userId`,
          emailInput
        );

        setUserId(response.data);
      } catch (error) {
        console.error("Error fetching user ID:", error);
      }
    };

    fetchUserId();
  }, [email]);

  /*   useEffect(() => {
      const fetchEnrolledCourses = async () => {
        try {
          const data = {
            userId: `${userId}`,
          };
          const response = await axios.post(
            `${nodeapiBaseUrl}/enrolled-courses`,
            data
          );
  
          setUserCourses(response.data[0]);
        } catch (error) {
          console.error("Error fetching enrolled courses:", error);
        }
      };
  
      if (userId) {
        fetchEnrolledCourses();
      }
    }, [userId]);
   */
  const fetchCourseContents = async (courseId) => {
    try {
      const response = await axios.get(
        `${nodeapiBaseUrl}/course-contents/${process.env.REACT_APP_AWARENESS_CONTENT}`
      );

      setCourses(response.data);  

      setModule(response.data.length);
    } catch (error) {
      console.error("Error fetching course contents:", error);
      // Handle error appropriately
    }
  };

  useEffect(() => {
    // Fetch courses from your API
    const fetchCourses = async () => {
      try {
        const response = await axios.get(`${nodeapiBaseUrl}/courses`);

        for (const course of response.data) {
          if (course.fullname === "Awareness on Automotive Industry") {
            const courseId = course.id;
            const courseSummary = course.summary;
            const courseFullName = course.fullname;
            console.log(courseSummary,'summary here')
            // You can set these values in your state variables if needed
            setCourseId(courseId);
            setSummary(course.summary);
            setFullName1(courseFullName);

            break; // Exit the loop once you find the course
          }
        }
        setSummary(response[0].summary)
        console.log(response[0].summary)

        console.log([...response,'lenovo'])
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchCourses();
  }, []);

  useEffect(() => {
    if (courseId) {
      fetchCourseContents(courseId); // Fetch user's enrolled courses when userId is available
    }
  }, [courseId]);

  /*   useEffect(() => {
      const fetchCourseCategories = async (userId) => {
        try {
          const response = await axios.post(
            `${nodeapiBaseUrl}/course-category`,
            {
              params: {
                userId,
              },
            }
          );
  
          setCategories(response.data);
        } catch (error) {
          console.error("Error fetching enrolled courses:", error);
        }
      };
  
      fetchCourseCategories(); // Fetch user's enrolled courses when userId is available
    }, []); */

  const generateMoodleCourseUrl = (courseId) => {
    return `${oidcConfig.moodleBaseUrl}/course/view.php?id=${courseId}&sectionid=123`;
  };

  const onCourseClick = () => {
    const url = courseResumeUrl;
    console.log(url, 'Loggin Here')
    // window.open(url, '_blank', 'noopener noreferrer');
    window.location.href = url;
  };

  useEffect(() => {
    const oidcLoginUrl = `${oidcConfig.moodleBaseUrl}/course/view.php?id=${process.env.REACT_APP_OIDC_LOGIN_ID}`;

    setMoodleSSOUrl(oidcLoginUrl);
  }, []);

  const handleLoginClick = () => {
    const url = courseResumeUrl;
    window.location.href = url;
  };

  function CourseSummary({ summary }) {
    // Using dangerouslySetInnerHTML to render HTML content
    /* return (
      <div
        style={{
          alignSelf: "stretch",
          color: "#4A5965",
          fontSize: 16,
          fontFamily: "Inter",
          fontWeight: "400",
          wordWrap: "break-word",
        }}
        dangerouslySetInnerHTML={{ __html: summary }}
      />
    ); */

    return <p>The automotive industry is one of the most significant economic sectors by revenue and encompasses a wide range of companies and organizations involved in the design, development, manufacturing, marketing, and selling of motor vehicles. It is also one of the world's most important economic sectors in terms of revenue</p>
  }

  const mediaQueryDesktop = window.matchMedia("(min-width:1152px)");
  const mediaQueryMobile = window.matchMedia("(max-width:576px)");

  const handleCloseSubmissionTimer = () => {
    setShowSubmissionTimer(false);
  };

  const isPopupShown = localStorage.getItem("hasShownDeadlinePopup");
  const location = useLocation();
  var deadLineUnix = "";
  var courseResumeUrl = "";
  var moduleCompPercentage = "";
  const deadlineUnixDate = location.state && location.state.deadlineUnix;
  if (deadlineUnixDate !== null && deadlineUnixDate !== undefined)
    deadLineUnix = deadlineUnixDate;

  const courseUrl = location.state && location.state.courseUrl;
  if (courseUrl !== null && courseUrl !== undefined)
    courseResumeUrl = courseUrl;

  const moduleCompletePercentage =
    location.state && location.state.moduleCompletePercentage;
  if (
    moduleCompletePercentage !== null &&
    moduleCompletePercentage !== undefined
  )
    moduleCompPercentage = moduleCompletePercentage;

  return (
    <div>
      <div
        hidden={
          new Date(deadLineUnix * 1000) < new Date() || showSubmissionTimer
        }
      >
        <SubmissionTimer
          deadlineUnix={deadLineUnix}
          onClose={handleCloseSubmissionTimer}
        />
      </div>
      <div
        style={{ width: "100%", background: "#F6F9FC", display: "inline-flex" }}
      >
        <div
          className="col-md-12"
          style={{
            display: "inline-flex",
            alignSelf: "center",
            flexDirection: "column",
            placeItems: "center",
          }}
        >
          <div className="row" style={{ margin: 0, width: "100%" }}>
            <div className="col-md-12" style={{ padding: 0 }}>
              <div
                className="cd_header"
                style={{
                  width: "100%",
                  background: "#F6F9FC",
                  borderBottom: "1px #E2EBF3 solid",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 5,
                  display: "inline-flex",
                }}
              >
                <NavLink
                  to="/dashboard/journey"
                  style={{ textDecoration: "none" }}
                >
                  <button
                    style={{ border: "none", padding: 0 }}
                    className="buttonbkcolornone1"
                  >
                    <img
                      src={Group}
                      style={{
                        marginTop: 0,
                        width: 18,
                        height: 15,
                        marginTop: "-3px",
                      }}
                      alt=""
                    />
                  </button>
                </NavLink>
                <div>
                  <i
                    className="bi bi-chevron-right"
                    style={{ fontSize: "12px", color: "#667A8B" }}
                  ></i>
                </div>
                <div
                  style={{
                    color: "#777D84",
                    fontSize: 14,
                    fontFamily: "Inter",
                    fontWeight: "400",
                    wordWrap: "break-word",
                    marginTop: "5px",
                  }}
                >
                  Automotive Industry Awareness Learning Path
                </div>
              </div>
            </div>
          </div>
          <div
            className=""
            style={{
              maxWidth: "1150px",
              width: "100%",
              display: "block",
              padding: 0,
              margin: 0,
            }}
          >
            <div className="row" style={{ height: "auto", margin: 0 }}>
              <div className="col-md-12 " style={{ padding: 0 }}>
                <div
                  className="cd_firstlayer"
                  style={{
                    height: "auto",
                    background: "white",
                    border: "1px #E2EBF3 solid",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    gap: 24,
                    display: "inline-flex",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      height: "auto",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      gap: 24,
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        alignSelf: "stretch",
                        height: "auto",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        gap: 16,
                        display: "flex",
                      }}
                    >
                      
                      <div
                        style={{
                          alignSelf: "stretch",
                          color: "#4A5965",
                          fontSize: 12,
                          fontFamily: "Inter",
                          fontWeight: "400",
                          wordWrap: "break-word",
                        }}
                      >
                        LEARNING PATH
                      </div>
                      <div
                        style={{
                          alignSelf: "stretch",
                          height: "auto",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          gap: 8,
                          display: "flex",
                        }}
                      >
                        <div
                          className="cd_heading"
                          style={{
                            color: "#00213D",
                            fontFamily: "Inter",
                            wordWrap: "break-word",
                          }}
                        >
                          Automotive Industry Awareness
                        </div>
                        <div
                          style={{
                            margin: 0,
                            color: "#4A5965",
                            fontSize: 16,
                            fontFamily: "Inter",
                            fontWeight: "400",
                            wordWrap: "break-word",
                          }}
                        >
                          <CourseSummary summary={summary} />
                        </div>
                      </div>
                    </div>

                    <div className="cd_skills" style={{}}>
                      <div
                        style={{
                          color: "#4A5965",
                          fontSize: 14,
                          fontFamily: "Inter",
                          fontWeight: "600",
                          wordWrap: "break-word",
                        }}
                      >
                        Key Skills:
                      </div>
                      <div
                        style={{
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: 8,
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        <div
                          className="cd_padding"
                          style={{
                            borderRadius: 28,
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: 10,
                            display: "inline-flex",
                          }}
                        >
                          <div
                            style={{
                              color: "#4A5965",
                              fontSize: 14,
                              fontFamily: "Inter",
                              fontWeight: "400",
                              wordWrap: "break-word",
                            }}
                          >
                            UX Design
                          </div>
                        </div>
                        <div
                          className="cd_padding"
                          style={{
                            borderRadius: 28,
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: 10,
                            display: "inline-flex",
                          }}
                        >
                          <div
                            style={{
                              color: "#4A5965",
                              fontSize: 14,
                              fontFamily: "Inter",
                              fontWeight: "400",
                              wordWrap: "break-word",
                            }}
                          >
                            Protoyping
                          </div>
                        </div>
                        <div
                          className="cd_padding"
                          style={{
                            borderRadius: 28,
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: 10,
                            display: "inline-flex",
                          }}
                        >
                          <div
                            style={{
                              color: "#4A5965",
                              fontSize: 14,
                              fontFamily: "Inter",
                              fontWeight: "400",
                              wordWrap: "break-word",
                            }}
                          >
                            Wireframing
                          </div>
                        </div>
                        <div
                          className="cd_padding"
                          style={{
                            borderRadius: 28,
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: 10,
                            display: "inline-flex",
                          }}
                        >
                          <div
                            style={{
                              color: "#4A5965",
                              fontSize: 14,
                              fontFamily: "Inter",
                              fontWeight: "400",
                              wordWrap: "break-word",
                            }}
                          >
                            UX Research
                          </div>
                        </div>
                        <div
                          className="cd_padding"
                          style={{
                            borderRadius: 28,
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: 10,
                            display: "inline-flex",
                          }}
                        >
                          <div
                            style={{
                              color: "#4A5965",
                              fontSize: 14,
                              fontFamily: "Inter",
                              fontWeight: "400",
                              wordWrap: "break-word",
                            }}
                          >
                            Usability Testing
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {mediaQueryDesktop.matches ? (
                    <CdDesktop
                      module={module}
                      handleLoginClick={handleLoginClick}
                      progressPercentage={moduleCompPercentage}
                    />
                  ) : (
                    <div></div>
                  )}

                  {mediaQueryMobile.matches ? (
                    <CdMobile
                      module={module}
                      handleLoginClick={handleLoginClick}
                      progressPercentage={moduleCompPercentage}
                    />
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
            <div
              className="row cd_pad"
              style={{
                height: "auto",
                display: "inline-flex",
                marginBottom: "5%",
              }}
            >
              <div className="col-md-12">
                <div
                  style={{
                    paddingBottom: 24,
                    width: "auto",
                    position: "relative",
                    color: "#00213D",
                    fontSize: 24,
                    fontFamily: "Inter",
                    fontWeight: "700",
                    wordWrap: "break-word",
                  }}
                >
                  Learning Path Journey
                </div>
              </div>

              <div className="col-md-8">
                <div
                  className="cd_gap"
                  style={{
                    width: "100%",
                    height: "auto",
                    position: "relative",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    display: "inline-flex",
                  }}
                >
                  {Array.isArray(courses) &&
                    courses.map((course, index) => (
                      <ModulesSubcomponent
                        content={{ ...course, summary: extractTextFromParagraphs(course.summary) }}
                        target={index}
                        key={index}
                      />
                    ))}
                </div>
              </div>

              <div className="col-md-4 margincontrolmbview">
                <div className="desktopview_sticky">
                  <div
                    className="cd_sticky"
                    style={{
                      borderRadius: 12,
                      border: "1px #E2EBF3 solid",
                      height: "auto",
                      padding: 24,
                      position: "relative",
                      background: "white",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      gap: 20,
                      display: "inline-flex",
                    }}
                  >
                    <div
                      style={{
                        alignSelf: "stretch",
                        height: "auto",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        gap: 16,
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          alignSelf: "stretch",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: 20,
                          display: "inline-flex",
                        }}
                      >
                        {/* <img src={Automotive_Course_Icon} style={{ width: 48, height: 48, }} alt="" /> */}
                        <div
                          style={{
                            flex: "1 1 0",
                            color: "#00213D",
                            fontSize: 18,
                            fontFamily: "Inter",
                            fontWeight: "700",
                            wordWrap: "break-word",
                          }}
                        >
                          Automotive Industry Awareness
                        </div>
                      </div>
                      {userCourses && (
                        <div
                          key={course.id}
                          style={{
                            flexDirection: "column",
                            alignSelf: "stretch",
                            justifyContent: "flex-start",
                            display: "inline-flex",
                          }}
                        >
                          <progress
                            style={{
                              height: 8,
                              width: "100%",
                              marginBottom: 8,
                              borderTopRightRadius: 10,
                              borderWidth: "1px",
                            }}
                            value={moduleCompPercentage}
                            max="100"
                          ></progress>
                          <div
                            style={{
                              color: "#4A5965",
                              fontSize: 12,
                              fontFamily: "Inter",
                              fontWeight: "400",
                              wordWrap: "break-word",
                            }}
                          >
                            {Math.round(moduleCompPercentage)}% completed
                          </div>
                          <button
                            onClick={() => onCourseClick()}
                            style={{
                              border: "none",
                              justifyContent: "center",
                              marginTop: 20,
                              padding: "12px 24px",
                              borderRadius: 8,
                              background: "#0B6AEA",
                              alignItems: "center",
                              display: "inline-flex",
                            }}
                          >
                            <div
                              style={{
                                textAlign: "center",
                                color: "white",
                                fontSize: 14,
                                fontFamily: "Inter",
                                fontWeight: "600",
                                wordWrap: "break-word",
                              }}
                            >
                              {moduleCompPercentage === 100
                                ? "Rewatch the Course"
                                : moduleCompPercentage > 0 &&
                                  moduleCompPercentage <= 100
                                  ? "Resume Learning"
                                  : "Start Learning"}
                            </div>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Describing;
