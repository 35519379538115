import {
  Profile_details_header,
  Profile_details_generalview,
  Profile_edit_view_content,
  Profile_edit_view_footer,
} from "../Profile_components";
import add_details from "../../../assets/img/add_details.svg";
import dateFormat from "dateformat";
import Pencil from "../../../assets/img/Pencil.svg";
import delete_details from "../../../assets/img/delete_details.svg";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import EditWorkExperience from "./EditWorkExperience";
import AddWorkExperience from "./AddWorkExperience";
import { useState } from "react";

const WorkExperience = (props) => {
  const {
    checkingvalues,
    profileData,
    setworkexperience_action,
    deleteablebtnclick,
    editablebtnclick,
    workexperience_action,
    sectionRefs,
    workexperience_editid,
    handleEduEditDetailsClick,
    saveWorkDataToKeyCloak,
    discardfunction,
    handleworkexperiencesubmit,
    saveEditedWorkDataToKeyCloak,
    handlenewworkexperiencesubmit,
    fieldWisePercentage,
  } = props;

  const [workDetails, setWorkDetails] = useState({
    companyname: "",
    empType: "",
    workStartDate: "",
    isWorking: false,
    workEndDate: "",
    designation: "",
    jobDesc: "",
    skillUsedInWork: [],
  });

  /*   const workSchema = Yup.object().shape({
    companyname: Yup.string().required("Company Name is required"),
    empType: Yup.string().required("Employment Type is required"),
    workStartDate: Yup.date()
      .max(new Date(), "Work Start Date cannot be in the future")
      .required("Work Start Date is required"),
    workEndDate: Yup.date().when(["isWorking", "workStartDate"], {
      is: (isWorking, workStartDate) => !isWorking && workStartDate,
      then: (schema) =>
        schema
          .min(
            Yup.ref("workStartDate"),
            "Work End Date cannot be before Work Start Date"
          )
          .max(new Date(), "Work End Date cannot be in the future")
          .required("Work End Date is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    isWorking: Yup.boolean(),
    designation: Yup.string().required("Designation is Required"),
    jobDesc: Yup.string().required("Job Description is Required"),
  });
 */

  const workSchema = Yup.object().shape({
    companyname: Yup.string().required("Company Name is required"),
    empType: Yup.string().required("Employment Type is required"),
    workStartDate: Yup.date()
      .max(new Date(), "Work Start Date cannot be in the future")
      .required("Work Start Date is required"),
    workEndDate: Yup.date().when("isWorking", {
      is: (isWorking) => !isWorking,
      then: (schema) =>
        schema
          .min(
            Yup.ref("workStartDate"),
            "Work End Date cannot be before Work Start Date"
          )
          .max(new Date(), "Work End Date cannot be in the future")
          .required("Work End Date is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    isWorking: Yup.boolean(),
    designation: Yup.string().required("Designation is Required"),
    jobDesc: Yup.string().required("Job Description is Required"),
    skillUsedInWork: Yup.array()
      .of(Yup.string())
      .min(1, "Select atleast one skill"),
  });

  const initialValues = {
    companyname: "",
    empType: "",
    workStartDate: "",
    isWorking: false,
    workEndDate: "",
    designation: "",
    jobDesc: "",
    skillUsedInWork: [],
  };

  return (
    <div>
      {/* view start*/}
      {workexperience_action == "view" ? (
        <div
          ref={sectionRefs.workexperienceid}
          id="workexperienceid"
          className="education-view-container"
        >
          <Profile_details_header
            bottomborder={`${
              profileData.workexperience.length ? "profilebottomborder" : ""
            }`}
            adddetailsbtn="yes"
            clickname={() => setworkexperience_action("add")}
            imgtoshow={add_details}
            topic="Work Experience / Internship Details"
            fieldWisePercentage={fieldWisePercentage.work}
          />
          <div className="education-list-container ">
            {profileData.workexperience.map((item, index) => {
              return (
                <div className="education-list">
                  <div className="card-container">
                    <div className="work-lists">
                      <div className="certificate-cards-container">
                        <Profile_details_generalview
                          heading={item.companyname}
                          deletebtnclick={() => deleteablebtnclick(index)}
                          editbtnclick={() => editablebtnclick(index)}
                          description={
                            item.designation +
                            " " +
                            dateFormat(item.workStartDate, "yyyy") +
                            " - " +
                            `${
                              item.isWorking
                                ? "Still Working"
                                : dateFormat(item.workEndDate, "yyyy")
                            }`
                          }
                          editicon={Pencil}
                          deleteicon={delete_details}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        ""
      )}
      {/* view end */}

      {/* edit start */}

      {workexperience_action === "edit" ? (
        <div
          ref={sectionRefs.workexperienceid}
          id="workexperienceid"
          className="work-experience-view-container"
        >
          <Profile_details_header
            bottomborder="profilebottomborder"
            clickname={handleEduEditDetailsClick}
            imgtoshow={add_details}
            topic="Work Experience / Internship Details"
          />

          {workexperience_action === "edit" &&
            profileData.workexperience &&
            profileData.workexperience.map((item, index) => {
              if (index === workexperience_editid) {
                return (
                  <Formik
                    initialValues={item}
                    validationSchema={workSchema}
                    validateOnChange={true}
                    onSubmit={(values) => {
                      saveEditedWorkDataToKeyCloak(values, index);
                      setworkexperience_action("view");
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      setErrors,
                    }) => (
                      <Form fullwidth style={{ width: "100%" }}>
                        <EditWorkExperience
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          setWorkDetails={setWorkDetails}
                          workDetails={item}
                          len={1}
                        />
                        <Profile_edit_view_footer
                          discard_editview={discardfunction}
                          errors={errors}
                        />
                      </Form>
                    )}
                  </Formik>
                );
              }
            })}
        </div>
      ) : null}

      {workexperience_action == "add" ? (
        <div
          ref={sectionRefs.workexperienceid}
          id="workexperienceid"
          className="work-experience-view-container"
        >
          <Profile_details_header
            bottomborder="profilebottomborder"
            clickname={handleEduEditDetailsClick}
            imgtoshow={add_details}
            topic="Work Experience / Internship Details"
          />
          <Formik
            validationSchema={workSchema}
            initialValues={initialValues}
            onSubmit={(values, { resetForm }) => {
              saveWorkDataToKeyCloak(values);
              resetForm(); // Reset form after successful submission
              setworkexperience_action("view");
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form style={{ width: "100%" }}>
                <AddWorkExperience
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  setWorkDetails={setWorkDetails}
                  workDetails={workDetails}
                  handleBlur={handleBlur}
                />
                <Profile_edit_view_footer
                  discard_editview={discardfunction}
                  handleSubmit={handleSubmit}
                />
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        ""
      )}

      {/* add end */}
    </div>
  );
};

export default WorkExperience;
