import Headers from "../../Headers";
import Home from "../Dashboard/Home";
import AssignmentDownloadPage from "./AssignmentDownloadPage"
const AssignmentDownload = () => {
  const content = <AssignmentDownloadPage />;
  return (
    <div>
      <Headers />
      <Home contentdes={content} />
    </div>
  );
}

export default AssignmentDownload;
